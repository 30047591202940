export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const weekDayAbbrevs = weekDays.map((d) => `${d.slice(0, 3)}`);
export const monthAbbrevs = months.map((mo, i) => i === 8 ? `${mo.slice(0, 4)}` : `${mo.slice(0, 3)}`);
const formatDate = (format = "%-m/%-d/%Y %-I:%M %p", timestamp = Date.now()) => {

  const date = new Date(timestamp);

  var dateStr = format;
  // Weekday
  dateStr = dateStr.replace(/%a/g, weekDayAbbrevs[date.getDay()]);
  dateStr = dateStr.replace(/%A/g, weekDays[date.getDay()]);
  dateStr = dateStr.replace(/%w/g, date.getDay());
  // Month Day
  dateStr = dateStr.replace(/%d/g, String(date.getDate()).padStart(2, "0"));
  dateStr = dateStr.replace(/%-d/g, date.getDate());
  // Month
  dateStr = dateStr.replace(/%b/g, monthAbbrevs[date.getMonth()]);
  dateStr = dateStr.replace(/%B/g, months[date.getMonth()]);
  dateStr = dateStr.replace(/%m/g, String((date.getMonth() + 1)).padStart(2, "0"));
  dateStr = dateStr.replace(/%-m/g, (date.getMonth() + 1));
  // Year
  dateStr = dateStr.replace(/%y/g, String(date.getFullYear()).slice(String(date.getFullYear()).length - 2, String(date.getFullYear()).length));
  dateStr = dateStr.replace(/%-y/g, parseInt(String(date.getFullYear()).slice(String(date.getFullYear()).length - 2, String(date.getFullYear()).length)));
  dateStr = dateStr.replace(/%Y/g, date.getFullYear());
  // Hour
  dateStr = dateStr.replace(/%H/g, String(date.getHours()).padStart(2, "0"));
  dateStr = dateStr.replace(/%-H/g, date.getHours());
  dateStr = dateStr.replace(/%I/g, String(((date.getHours() + 11) % 12) + 1).padStart(2, "0"));
  dateStr = dateStr.replace(/%-I/g, (((date.getHours() + 11) % 12) + 1));
  dateStr = dateStr.replace(/%p/g, (date.getHours() < 12 ? "AM" : "PM"));
  // Minute
  dateStr = dateStr.replace(/%M/g, String(date.getMinutes()).padStart(2, "0"));
  dateStr = dateStr.replace(/%-M/g, date.getMinutes());
  // Seconds
  dateStr = dateStr.replace(/%S/g, String(date.getSeconds()).padStart(2, "0"));
  dateStr = dateStr.replace(/%-S/g, date.getSeconds());
  // Microseconds
  dateStr = dateStr.replace(/%f/g, String(date.getMilliseconds()).padStart(3, "0"));
  // Timezone
  dateStr = dateStr.replace(/%z/g, (date.getTimezoneOffset() > 0 ? "-" : "+") + String(Math.floor(date.getTimezoneOffset() / 60)).padStart(2, "0") + String(date.getTimezoneOffset() % 60).padStart(2, "0"));
  return dateStr;
};

export default formatDate;