import React, { useEffect, useRef, useState } from "react";

import Alert from "../components/Alert";
import PageContainer from "../components/PageContainer";

import countries from "../extensions/countries";
import { usePage } from "../contexts/PageContext";
import { useApi } from "../contexts/ApiContext";

const Contact = () => {
  const { setCurrentPage } = usePage();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "contact",
        subpage: null,
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "Contact | EVS Solutions",
          description: "Connect today and begin a new journey together.",
          canonical: "https://evssolutions.ca/contact",
          meta: {
            charset: "utf-8",
            name: {
              title: "Contact | EVS Solutions",
              keywords: "evs,solutions,contact,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "Connect today and begin a new journey together."
            }
          }
        }
      });
    };
    unsub();
  }, []);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const callingCodeRef = useRef();
  const phoneRef = useRef();
  const companyRef = useRef();
  const positionRef = useRef();
  const messageRef = useRef();
  const termsRef = useRef();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true);
    const body = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: emailRef.current.value.toLowerCase(),
      phone: phoneRef.current.value,
      callingCode: callingCodeRef.current.value,
      company: companyRef.current.value,
      position: positionRef.current.value,
      message: messageRef.current.value,
      terms: termsRef.current.checked
    }
    const res = await apiFetch("/contact", "POST", body);
    if (res.ok) {
      firstNameRef.current.value = "";
      lastNameRef.current.value = "";
      emailRef.current.value = "";
      phoneRef.current.value = "";
      messageRef.current.value = "";
      termsRef.current.checked = false;
      setMessage("Message successfully sent!");
      setLoading(false);
    }
    else {
      try {
        const data = await res.json();
        setError({ ...data });
      }
      catch (e) {
        setError({ detail: "Unable to send message.", stack: e, all: true });
      }
      setLoading(false);
    }
  };

  return (
    <PageContainer className="!pt-24">
      <div className="card w-full max-w-screen-sm mx-auto">
        <div className="card-body flex flex-col gap-4">
          <h2>Contact</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* First Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.firstName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                autoComplete="off"
                className="form-control"
                ref={firstNameRef}
                disabled={loading}
              />
            </div>
            {/* Last Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.lastName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                autoComplete="off"
                className="form-control"
                ref={lastNameRef}
                disabled={loading}
              />
            </div>
            {/* Email */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.email) || error.all)) ? "error" : ""}`}>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                autoComplete="off"
                className="form-control"
                ref={emailRef}
                disabled={loading}
              />
            </div>
            {/* Phone */}
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.phone) || error.all)) ? "error" : ""}`}>
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group">
                <select ref={callingCodeRef} className="form-control" disabled={loading}>
                  {[...countries.getContinents(true)].map(continent => {
                    return (
                      <optgroup label={continent} key={continent}>
                        {[...countries.getCountriesByContinent(continent, "callingCode")
                          .filter(c => c.callingCode !== null)].map(c => {
                            return (
                              <option value={c.callingCode} key={c.country}>+{c.callingCode} ({c.abbrev ? c.abbrev : c.country})</option>
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </select>
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  ref={phoneRef}
                  autoComplete="off"
                  disabled={loading}
                />
              </div>
            </div>
            {/* Company */}
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.company) || error.all)) ? "error" : ""}`}>
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                autoComplete="off"
                className="form-control"
                ref={companyRef}
                disabled={loading}
              />
            </div>
            {/* Position */}
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.position) || error.all)) ? "error" : ""}`}>
              <label htmlFor="position">Position</label>
              <input
                type="text"
                id="position"
                autoComplete="off"
                className="form-control"
                ref={positionRef}
                disabled={loading}
              />
            </div>
            {/* Message */}
            <div className={`form-group required md:col-span-2 ${(error && ((error.errors && error.errors.message) || error.all)) ? "error" : ""}`}>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className="form-control"
                rows="3"
                autoComplete="off"
                ref={messageRef}
                disabled={loading}
              ></textarea>
            </div>
            {/* Checkboxes */}
            <div className="form-checkboxes md:col-span-2">
              <div className={`form-checkbox ${(error && ((error.errors && error.errors.terms) || error.all)) ? "error" : ""}`}>
                <input
                type="checkbox"
                id="terms"
                className="input-checkbox"
                ref={termsRef}
                disabled={loading}
                />
                <label htmlFor="terms">I have read and agree to the <a className="link" href="/privacy-policy" target="_blank">Privacy Policy</a></label>
              </div>
            </div>
            {/* Success Message */}
            <Alert variant="success" className="md:col-span-2" hidden={!message} onHide={() => setMessage(null)}>
              {message && (
                <span>{message}</span>
              )}
            </Alert>
            {/* Error Message */}
            <Alert variant="danger" className="md:col-span-2" hidden={!error} onHide={() => setError(null)}>
              {error && (
                <>
                  {(typeof error === "string") ? (
                    <span>{error}</span>
                  ) : (
                    <div className="flex flex-col">
                      <span>{error.detail || "Unable to send message."}</span>
                      {error.errors && (
                        <ul className="list-disc">
                          {Object.keys(error.errors).map(e => {
                            return (
                              <li key={e} className="ml-5">{error.errors[e]}</li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}
            </Alert>
            {/* Send */}
            <button type="submit" className="btn btn-main md:col-span-2" disabled={loading}>
              Send Message
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
};

export default Contact;