import React, { createElement, useEffect, useRef, useState } from "react";
import { usePage } from "../../contexts/PageContext";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../contexts/ApiContext";
import PageContainer from "../../components/PageContainer";
import Alert from "../../components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faQrcode } from "@fortawesome/free-solid-svg-icons";
import AdminNav from "../../components/admin/AdminNav";
import AuthRequired from "../../components/admin/AuthRequired";
import { QRCode } from "react-qrcode-logo";
import validator from "validator";

const AdminQRGenerator = () => {
  const { setCurrentPage } = usePage();
  const { currentUser, currentLogin, logOut } = useAuth();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = async () => {
      setCurrentPage({
        id: "admin",
        subpage: "qr",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "QR Code Generator - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/qr-generator",
          meta: {
            charset: "utf-8",
            name: {
              title: "QR Code Generator - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,qr,code,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      await updatePages();
    };
    unsub();
  }, []);

  const [pages, setPages] = useState(null);
  const updatePages = async () => {
    const res = await apiFetch("/admin/metrics/pages");
    if (res.ok) {
      const data = await res.json();
      setPages(data.pages);
    }
  };

  const qrNameRef = useRef();
  const handleDownload = () => {
    const canvas = document.getElementsByTagName("canvas")[0];
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `${qrNameRef.current.value}.png`;
    link.click();
  };

  const [qrLink, setQrLink] = useState("");
  const [qrStyle, setQrStyle] = useState("squares");
  const [qrEyeRadius, setQrEyeRadius] = useState(0);
  const handleLinkChange = (e) => {
    setQrLink(e.target.value);
  };
  const handleStyleChange = (e) => {
    setQrStyle(e.target.value);
  };
  const handleEyeRadiusChange = (e) => {
    setQrEyeRadius(parseFloat(e.target.value));
  };
  useEffect(() => {
    const unsub = () => {
      if (!qrNameRef.current) return;
      qrNameRef.current.value = qrLink.match(/(http(s?):\/\/)?([^?]*)/)[3].toLowerCase().replace(/\./g, "-").replace(/\//g, "_").replace().replace(/[^a-z_-]/g, "-").replace(/_$/g, "");
    };
    unsub();
  }, [qrLink]);


  if (!currentUser) return (
    <AuthRequired />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="card max-w-screen-md">
        {validator.isURL(qrLink) && (
          <div className="card-header flex flex-col items-center gap-2">
            <div className="border-px border-main-500">
              <QRCode
                value={qrLink}
                size={150}
                logoImage="/imgs/favicon.ico"
                removeQrCodeBehindLogo={true}
                qrStyle={qrStyle}
                eyeRadius={qrEyeRadius}
              />
            </div>
            <input
              type="text"
              className="form-control w-full max-w-[15rem]"
              autoComplete="off"
              autoCapitalize="off"
              ref={qrNameRef}
            />
            <button className="btn btn-main w-full max-w-[15rem]" onClick={handleDownload}>
              Download <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
        )}
        <div className="card-body flex flex-col items-center gap-4">
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="url">URL</label>
            <input
              id="url"
              type="text"
              className="w-full form-control"
              autoComplete="off"
              autoCapitalize="off"
              value={qrLink}
              onChange={handleLinkChange}
              list="pages"
            />
            {pages &&
              <datalist id="pages">
                {pages.map((p, i) => {
                  return <option key={i} value={`https://evssolutions.ca${p}`}></option>
                })}
              </datalist>
            }
            {!qrLink && (
              <p className="w-full text-sm text-gray-400">Enter a valid URL to see a QR Code.</p>
            )}
            {(qrLink && !validator.isURL(qrLink)) && (
              <p className="w-full text-sm text-red-600">Invalid URL.</p>
            )}
          </div>
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="style">Style</label>
            <select
              id="style"
              type="range"
              className="form-control w-full"
              value={qrStyle}
              onChange={handleStyleChange}
            >
              <option value="dots">Dots</option>
              <option value="fluid">Fluid</option>
              <option value="squares">Squares</option>
            </select>
          </div>
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="eyeRadius">Eye Radius ({qrEyeRadius}%)</label>
            <input
              id="eyeRadius"
              type="range"
              className="form-control w-full"
              min={0}
              max={20}
              step={5}
              value={qrEyeRadius}
              onChange={handleEyeRadiusChange}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminQRGenerator;