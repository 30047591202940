import React from "react";
import bulbLogo from "../assets/Bulb.svg";

const Spinner = ({ size = 64 }) => {
  return (
    <>
      <div className="spinner" style={{ '--size': `${size}px` }}>
        <img src={bulbLogo} alt="" />
      </div>
    </>
  );
};

export default Spinner;