import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";
import FormatDate from "../../../components/FormatDate";

const AdminVisit = () => {

  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const { metricsId } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "visit",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "View Page Visit - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/visits/${metricsId}`,
          meta: {
            charset: "utf-8",
            name: {
              title: "View Page Visit - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,metrics,view,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateVisit();
    };
    unsub();
  }, []);

  const [visit, setVisit] = useState(null);
  const [visitError, setVisitError] = useState(null);
  const updateVisit = async () => {
    setVisit(null);
    const res = await apiFetch(`/admin/metrics/visits/${metricsId}`);
    if (res.ok) {
      const data = await res.json();
      setVisit(data.visit);
    }
    else {
      try {
        const data = await res.json();
        setVisitError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setVisitError({ detail: "Unable to update visit.", stack: e, all: true });
      }
    }
  };



  if (!currentUser) return (
    <AuthRequired />
  );

  if (!visit && !visitError) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (visitError && visitError.notFound) return (
    <ErrorPage statusName="Visit Not Found" message="The page visit you are looking for does not exist. Please check the metrics ID and try again." backLink="/admin/metrics" backText="Back to metrics" hideNavbar={true} />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {visitError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!visitError} onHide={() => setVisitError(null)} variant="danger">
            <span>{visitError && visitError.detail}</span>
          </Alert>
        </div>
      )}
      {/* Visit */}
      {visit && (
        <div className="card w-full">
          <div className="card-body grid md:grid-cols-2 gap-4">
            {/* Visit Info */}
            <div className="flex flex-col gap-4">
              <div>
                <h3>
                  <Link to={visit.path} target="_blank" className="link">
                    {visit.path}
                  </Link>
                </h3>
                <span className="text-gray-400">Path</span>
              </div>
              <div>
                <h3>
                  <Link to={`/admin/ips/${visit.ip}`} className="link">
                    {visit.ip}
                  </Link>
                </h3>
                <span className="text-gray-400">IP</span>
              </div>
              <div>
                <h3>
                  <FormatDate timestamp={visit.timestamp} />
                </h3>
                <span className="text-gray-400">Timestamp</span>
              </div>
            </div>
            {/* User Info */}
            <div className="flex flex-col gap-2">
              <h4>User Info</h4>
              {visit.userId ? (
                <>
                  <div>
                    <p className="text-sm">
                      <Link to={`/admin/users/${visit.userId}`} className="link">{visit.username}</Link>
                    </p>
                    <p className="text-xs text-gray-400">Username</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      {visit.firstName} {visit.lastName}
                    </p>
                    <p className="text-xs text-gray-400">Name</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      <a href={`mailto:${visit.email}`} className="link">{visit.email}</a>
                    </p>
                    <p className="text-xs text-gray-400">Email</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      {visit.phone ? (
                        <a href={`tel:${visit.phone}`} className="link">{visit.phone}</a>
                      ) : "N/A"}
                    </p>
                    <p className="text-xs text-gray-400">Phone</p>
                  </div>
                </>
              ) : (
                <span className="text-gray-400">No user</span>
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AdminVisit;