import React from "react";
import { motion, useScroll } from "framer-motion";

const ProgressBar = () => {
  const { scrollYProgress } = useScroll();
  return (
    <motion.div
      className="fixed top-16 left-0 right-0 h-2 bg-main-500 origin-left"
      style={{ scaleX: scrollYProgress }}
    />
  );
};

export default ProgressBar;