import React, { useEffect, useState } from "react";
import { usePage } from "../../contexts/PageContext";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useApi } from "../../contexts/ApiContext";
import PageContainer from "../../components/PageContainer";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faQrcode, faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import AdminNav from "../../components/admin/AdminNav";
import FormatDate from "../../components/FormatDate";
import AuthRequired from "../../components/admin/AuthRequired";
import FormatNumber from "../../components/FormatNumber";

const Admin = () => {
  const { setCurrentPage } = usePage();
  const { currentUser, currentLogin, logOut } = useAuth();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = async () => {
      setCurrentPage({
        id: "admin",
        subpage: "dashboard",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Dashboard - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin",
          meta: {
            charset: "utf-8",
            name: {
              title: "Dashboard - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      await updateUnreadMessages();
      await updateMetricsSummary();
      await updateUsers();
      await updateRequests();
    };
    unsub();
  }, []);

  const [unreadMessages, setUnreadMessages] = useState(null);
  const [unreadMessagesAlert, setUnreadMessagesAlert] = useState(false);
  const [unreadMessageAmount, setUnreadMessageAmount] = useState(0);
  const [unreadMessagesError, setUnreadMessagesError] = useState(null);
  const updateUnreadMessages = async () => {
    setUnreadMessages(null);
    setUnreadMessagesAlert(false);
    setUnreadMessagesError(null);
    const res = await apiFetch("/admin/messages/unread");
    if (res.ok) {
      const data = await res.json();
      setUnreadMessages(data.messages);
      setUnreadMessageAmount(data.results);
      if (data.results > 0) {
        setUnreadMessagesAlert(true);
      }
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setUnreadMessagesError(data.detail);
        setUnreadMessageAmount("-");
      }
      catch {
        setUnreadMessagesError("Unable to get unread messages.");
        setUnreadMessages([]);
        setUnreadMessageAmount("-");
      }
    }
  };

  const [metricsSummary, setMetricsSummary] = useState(null);
  const [metricsSummaryError, setMetricsSummaryError] = useState(null);
  const updateMetricsSummary = async () => {
    setMetricsSummary(null);
    setMetricsSummaryError(null);
    const res = await apiFetch("/admin/metrics/summary");
    if (res.ok) {
      const data = await res.json();
      setMetricsSummary(data.summary);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setMetricsSummaryError(data.detail);
      }
      catch {
        setMetricsSummaryError("Unable to get metrics summary.");
        setMetricsSummary([]);
      }
    }
  };

  const [users, setUsers] = useState(null);
  const [userAmount, setUserAmount] = useState(0);
  const [usersError, setUsersError] = useState(null);
  const updateUsers = async () => {
    setUsers(null);
    setUsersError(null);
    const res = await apiFetch("/admin/users?page=1&perPage=3");
    if (res.ok) {
      const data = await res.json();
      setUsers(data.users);
      setUserAmount(data.results);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setUsersError(data.detail);
        setUserAmount("-");
      }
      catch {
        setUsersError("Unable to get users.");
        setUsers([]);
        setUserAmount("-");
      }
    }
  };

  const [requests, setRequests] = useState(null);
  const [requestsError, setRequestsError] = useState(null);
  const updateRequests = async () => {
    setRequests(null);
    setRequestsError(null);
    const res = await apiFetch("/admin/requests?page=1&perPage=3");
    if (res.ok) {
      const data = await res.json();
      setRequests(data.requests);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setRequestsError(data.detail);
      }
      catch {
        setRequestsError("Unable to get requests.");
        setRequests([]);
      }
    }
  };

  const [logoutLoading, setLogoutLoading] = useState(false);
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logOut();
    setLogoutLoading(false);
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {(unreadMessagesError || usersError || metricsSummaryError || requestsError || unreadMessagesAlert) && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!unreadMessagesError} onHide={() => setUnreadMessagesError(null)} variant="danger">
            <span>{unreadMessagesError && unreadMessagesError}</span>
          </Alert>
          <Alert hidden={!metricsSummaryError} onHide={() => setMetricsSummaryError(null)} variant="danger">
            <span>{metricsSummaryError && metricsSummaryError}</span>
          </Alert>
          <Alert hidden={!usersError} onHide={() => setUsersError(null)} variant="danger">
            <span>{usersError && usersError}</span>
          </Alert>
          <Alert hidden={!requestsError} onHide={() => setRequestsError(null)} variant="danger">
            <span>{requestsError && requestsError}</span>
          </Alert>
          {/* Messages */}
          <Alert hidden={!unreadMessagesAlert} onHide={() => setUnreadMessagesAlert(false)} variant="success">
            <span>You have <strong>{unreadMessages && unreadMessageAmount}</strong> unread message{(unreadMessages && unreadMessageAmount == 1) ? "" : "s"}. View {(unreadMessages && unreadMessageAmount == 1) ? "it" : "them"} <Link to="/admin/messages" className="link font-bold text-inherit hover:text-inherit focus:text-inherit">here</Link>.</span>
          </Alert>
        </div>
      )}
      {/* User Info */}
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-[auto,1fr] items-center gap-2">
          {currentUser.image ? (
            <img src={currentUser.image} className="avatar w-24 h-24" />
          ) : (
            <div className="avatar border-2 w-16 h-16 flex items-end justify-center overflow-hidden">
              <span className="text-[3.5rem] leading-[3rem] text-gray-300">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </div>
          )}
          <div className="flex flex-col w-full overflow-hidden">
            <p className="truncate">
              Logged in as <strong>{currentUser.firstName} {currentUser.lastName} ({currentUser.username})</strong>
            </p>
            <p className="truncate text-sm">{currentUser.email}</p>
            <button className="link text-sm w-fit p-0" onClick={handleLogout} disabled={logoutLoading}>
              Log Out <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </button>
          </div>
        </div>
      </div>
      {/* Utilities */}
      <div className="card">
        <div className="card-body grid grid-cols-3 gap-2">
          <h2 className="w-full col-span-3">Utilities</h2>
          <Link to="/admin/qr-generator" className="btn btn-main w-full py-4 flex flex-col justify-center" role="button">
            <h1 className="text-center">
              <FontAwesomeIcon icon={faQrcode} />
            </h1>
            <span className="text-center">QR Code Generator</span>
          </Link>
          <Link to={`/admin/users/${currentUser.userId}`} className="btn btn-outline-main w-full py-4 flex flex-col justify-center" role="button">
            <h1 className="text-center">
              <FontAwesomeIcon icon={faUser} />
            </h1>
            <span className="text-center">Profile</span>
          </Link>
          <Link to={`/admin/users/add`} className="btn btn-main w-full py-4 flex flex-col justify-center" role="button">
            <h1 className="text-center">
              <FontAwesomeIcon icon={faUserPlus} />
            </h1>
            <span className="text-center">Add User</span>
          </Link>
        </div>
      </div>
      {/* Unread Messages */}
      {unreadMessages && (
        <div className="card">
          <div className="card-body flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <h2>Unread Messages</h2>
              <h1 className="text-4xl">{unreadMessageAmount}</h1>
            </div>
            <table className="max-w-[30rem] flex flex-col gap-px overflow-scroll hide-scrollbar">
              <tbody className="bg-gray-50">
                {unreadMessages.slice(0, 3).map(m => {
                  return (
                    <tr key={m.messageId} className="grid grid-cols-2">
                      <td className="pr-2 truncate text-sm">
                        <Link
                          to={`/admin/messages/${m.messageId}`}
                          className="link text-inherit hover:text-inherit focus:text-inherit"
                        >
                          {m.email}
                        </Link>
                      </td>
                      <td className="text-right truncate text-sm">
                        <FormatDate timestamp={m.timestamp} />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="text-sm truncate">
                    <Link to="/admin/messages" className="link">View all</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!unreadMessages && !unreadMessagesError && (
        <div className="card">
          <div className="card-body">
            <h2>Unread Messages</h2>
            <div className="flex justify-center">
              <Spinner size={128} />
            </div>
          </div>
        </div>
      )}
      {/* Page Visits */}
      {(metricsSummary) && (
        <div className="card">
          <div className="card-body flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <h2>Page Visits</h2>
            </div>
            <div className="grid grid-cols-3">
              <div className="flex flex-col items-center">
                <h1 className="text-center">
                  <FormatNumber number={metricsSummary.day.count} format="short" />
                </h1>
                <p className="text-center text-sm text-gray-400">Last 24 hrs</p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-center">
                  <FormatNumber number={metricsSummary.week.count} format="short" />
                </h1>
                <p className="text-center text-sm text-gray-400">Last 7 days</p>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-center">
                  <FormatNumber number={metricsSummary.month.count} format="short" />
                </h1>
                <p className="text-center text-sm text-gray-400">Last 30 days</p>
              </div>
            </div>
            <span className="text-sm truncate">
              <Link to="/admin/metrics" className="link">View metrics</Link>
            </span>
          </div>
        </div>
      )}
      {!metricsSummary && !metricsSummaryError && (
        <div className="card">
          <div className="card-body">
            <h2>Page Visits</h2>
            <div className="flex justify-center">
              <Spinner size={128} />
            </div>
          </div>
        </div>
      )}
      {/* Users */}
      {users && (
        <div className="card">
          <div className="card-body flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <h2>Users</h2>
              <h1 className="text-4xl">{userAmount}</h1>
            </div>
            <table className="max-w-[30rem] flex flex-col gap-px overflow-scroll hide-scrollbar">
              <tbody className="bg-gray-50">
                {users.slice(0, 3).map(u => {
                  return (
                    <tr key={u.userId} className="grid grid-cols-2">
                      <td className="pr-2 truncate text-sm">
                        <Link
                          to={`/admin/users/${u.userId}`}
                          className="link text-inherit hover:text-inherit focus:text-inherit"
                        >
                          {u.username}
                        </Link>
                      </td>
                      <td className="text-right truncate text-sm">
                        {u.firstName} {u.lastName}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="text-sm truncate">
                    <Link to="/admin/users" className="link">View all</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!users && !usersError && (
        <div className="card">
          <div className="card-body">
            <h2>Users</h2>
            <div className="flex justify-center">
              <Spinner size={128} />
            </div>
          </div>
        </div>
      )}
      {/* Usage Monitoring */}
      {requests && (
        <div className="card">
          <div className="card-body flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <h2>Usage Monitoring</h2>
            </div>
            <table className="max-w-[30rem] flex flex-col gap-px overflow-scroll hide-scrollbar">
              <tbody className="bg-gray-50">
                {requests.map(r => {
                  return (
                    <tr key={r.requestId} className="grid grid-cols-2">
                      <td className="pr-2 truncate text-sm">
                        <span className={`py-0 tag ${r.ok === 0
                          ? "tag-danger"
                          : "tag-success"}`}>
                          {r.statusCode}
                        </span> <Link
                          to={`/admin/requests/${r.requestId}`}
                          className="link text-inherit hover:text-inherit focus:text-inherit"
                        >
                          <strong>{r.method}</strong> {r.path}
                        </Link>
                      </td>
                      <td className="text-right truncate text-sm">
                        <FormatDate timestamp={r.timestamp} />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="text-sm truncate">
                    <Link to="/admin/usage" className="link">View usage</Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!requests && !requestsError && (
        <div className="card">
          <div className="card-body">
            <h2>Usage Monitoring</h2>
            <div className="flex justify-center">
              <Spinner size={128} />
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between flex-wrap">
        <p className="text-sm pr-4">
          Last login: <FormatDate timestamp={currentLogin.lastLogin} />
        </p>
        <p className="text-sm pr-4">
          Session expires: <FormatDate timestamp={currentLogin.sessionExpires} />
        </p>
        <p className="text-sm">
          Cached IP: <Link
            to={`/admin/ips/${currentLogin.ip}`}
            className="link"
          >{currentLogin.ip}</Link>
        </p>
      </div>
    </PageContainer>
  );
};

export default Admin;