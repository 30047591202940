import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminUser = () => {

  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const { userId } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "user",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "View User - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/users/${userId}`,
          meta: {
            charset: "utf-8",
            name: {
              title: "View User - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,users,view,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateUser();
    };
    unsub();
  }, []);

  const [user, setUser] = useState(null);
  const [userError, setUserError] = useState(null);
  const updateUser = async () => {
    setUser(null);
    const res = await apiFetch(`/admin/users/${userId}`);
    if (res.ok) {
      const data = await res.json();
      setUser(data.user);
    }
    else {
      try {
        const data = await res.json();
        setUserError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setUserError({ detail: "Unable to update user.", stack: e, all: true });
      }
    }
  };



  if (!currentUser) return (
    <AuthRequired />
  );

  if (!user && !userError) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (userError && userError.notFound) return (
    <ErrorPage statusName="User Not Found" message="The user you are looking for does not exist. Please check the user ID and try again." backLink="/admin/users" backText="Back to users" hideNavbar={true} />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav title={user ? user.username : "User Not Found"} />
      {/* Alerts */}
      {userError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!userError} onHide={() => setUserError(null)} variant="danger">
            <span>{userError && userError.detail}</span>
          </Alert>
        </div>
      )}
      {user && (
        <div className="grid md:grid-cols-2 gap-8 md:gap-4">
          {/* User Info */}
          <div className="card w-full md:col-span-2">
            <div className="card-body grid grid-cols-[auto,1fr] gap-4 items-center">
              {/* Avatar */}
              {user.image ? (
                <img src={user.image} className="avatar w-24 h-24" />
              ) : (
                <div className="avatar border-4 w-32 h-32 flex items-end justify-center overflow-hidden">
                  <span className="text-[7rem] leading-[6rem] text-gray-300">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                </div>
              )}
              <div className="flex flex-col justify-center">
                <h2>{user.firstName} {user.lastName}</h2>
                <p className="text-gray-400">{user.username}</p>
              </div>
            </div>
          </div>
          {/* Contact Info */}
          <div className="card w-full">
            <div className="card-body flex flex-col gap-2">
              <h4>Contact Info</h4>
              <div>
                <p className="text-sm">
                  <a href={`mailto:${user.email}`} className="link">{user.email}</a>
                </p>
                <p className="text-xs text-gray-400">Email</p>
              </div>
              <div>
                <p className="text-sm">
                  {user.phone ? (
                    <a href={`tel:${user.phone}`} className="link">{user.phone}</a>
                  ) : "N/A"}
                </p>
                <p className="text-xs text-gray-400">Phone</p>
              </div>
            </div>
          </div>
          {/* Cached IP's */}
          <div className="card w-full">
            <div className="card-body flex flex-col gap-2">
              <h4>Cached IP's</h4>
              {user.ips.length > 0 ? (
                <div className="flex flex-col">
                  {user.ips.map(ip => {
                    return (
                      <p key={ip} className="text-sm truncate">
                        <Link to={`/admin/ips/${ip.ip}`} className="link">
                          {ip.ip}
                        </Link>
                      </p>
                    );
                  })}
                </div>
              ) : (
                <p className="text-sm truncate text-gray-400">
                  No IP's cached.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AdminUser;