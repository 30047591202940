import React, { useEffect, useRef } from "react";
import H from "@here/maps-api-for-javascript";

const Map = ({
  lat = null,
  lng = null,
  zoom = 10,
  width = "100%",
  height = "100%",
  maxWidth = "none",
  maxHeight = "none",
  marker = true,
  radius = 0
}) => {
  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const apiKey = process.env.REACT_APP_HERE_API_KEY;

  useEffect(() => {
    if (!map.current) {
      // Create a platform object with the API key and useCIT option
      platform.current = new H.service.Platform({
        apikey: apiKey
      });
      // Obtain the default map types from the platform object:
      const defaultLayers = platform.current.createDefaultLayers({
        pois: true
      });

      // Create a new map instance with the Tile layer, center and zoom level
      // Instantiate (and display) a map:
      const newMap = new H.Map(
        mapRef.current,
        defaultLayers.vector.normal.map,
        {
          zoom: zoom,
          center: {
            lat: lat,
            lng: lng,
          },
        }
      );

      // Add UI
      const ui = H.ui.UI.createDefault(newMap, defaultLayers);

      // Add panning and zooming behavior to the map
      const behavior = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(newMap)
      );

      // Set the map object to the reference
      map.current = newMap;

      // Add marker
      if (marker === true) map.current.addObject(new H.map.Marker({ lat, lng }));

      // Add radius
      if (radius > 0) map.current.addObject(new H.map.Circle({ lat, lng }, radius, {
        style: {
          fillColor: "rgba(0, 115, 204, 0.1)",
          strokeColor: "rgba(0, 115, 204, 0.5)"
        }
      }))
    }
  }, [lat, lng, zoom, width, height, maxWidth, maxHeight, marker, radius]);

  return (
    <div style={{ width, height, maxWidth, maxHeight }} ref={mapRef} />
  );
};

export default Map;