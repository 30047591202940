'use client';
import formatDate from "../functions/formatDate";

import React from "react";

const FormatDate = ({ format = "%-m/%-d/%Y %-I:%M %p", timestamp = Date.now() }) => {
  return (
    <span>{formatDate(format, timestamp)}</span>
  );
};

export default FormatDate;