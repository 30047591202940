import React, { useEffect } from 'react'
import { usePage } from '../contexts/PageContext'
import PageContainer from '../components/PageContainer'
import ProgressBar from '../components/ProgressBar'

const SpeakingEngagements = () => {
  const { setCurrentPage } = usePage()
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: 'speaking-engagements',
        subpage: null,
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: 'Speaking Engagements | EVS Solutions',
          description: '.',
          canonical: 'https://evssolutions.ca/deescalation',
          meta: {
            charset: 'utf-8',
            name: {
              title: 'Speaking Engagements | EVS Solutions',
              keywords:
                'evs,solutions,speaking,engagements,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner',
              'og:description': '',
            },
          },
        },
      })
    }
    unsub()
  }, [])
  return (
    <PageContainer className="max-w-screen-md flex flex-col gap-8">
      <ProgressBar />
      <div className="flex flex-col gap-4">
        <h1 className="text-center">Speaking Engagements</h1>
        <p className="text-center">
          <strong>Pete Wiesner</strong> is a remarkable and charismatic public
          speaker who possesses an innate ability to captivate audiences of all
          sizes. His talent lies not only in his eloquent storytelling but also
          in his genuine interaction with the crowd. Whether addressing a small
          gathering or an international audience, Pete's zest for engagement
          shines through.
        </p>
      </div>
      <hr />
      <div className="flex flex-col gap-4">
        <h2>Storytelling Mastery</h2>
        <p className="text-justify">
          Pete weaves narratives that resonate deeply with listeners. His
          personal experiences, including overcoming adversity, dealing with
          trauma, and advocating for mental health, form the backbone of his
          powerful speeches. Through vivid anecdotes, he paints a vivid picture,
          inviting empathy and understanding.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Connecting with Audiences</h2>
        <p className="text-justify">
          Pete's charisma extends beyond the stage. He effortlessly establishes
          rapport with his listeners, making them feel seen and heard. His
          authenticity and vulnerability create an atmosphere of trust, allowing
          people to open up about their own struggles. Pete's message becomes
          more than words — it becomes a lifeline for those who need it most.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>International Impact</h2>
        <p className="text-justify">
          Pete's reach knows no bounds. He has graced stages across the globe,
          sharing his triumphs, challenges, and insights. From conference halls
          to community centers, his voice resonates, breaking down barriers and
          fostering conversations around mental health.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Passion and Purpose</h2>
        <p className="text-justify">
          Pete's commitment to his cause is unwavering. His passion for
          transparency regarding personal mental health is contagious. He
          imparts a vital lesson: It's okay to ask, "YOU GOOD?" Pete's
          journey—from trauma survivor to advocate—inspires others to seek help,
          support one another, and prioritize their well-being.
        </p>
      </div>
      <hr />
      <p className="text-center">
        In summary, Pete Wiesner's impactful presence, heartfelt storytelling,
        and dedication to mental health advocacy leave an indelible mark on
        every audience fortunate enough to hear him speak.
      </p>
    </PageContainer>
  )
}

export default SpeakingEngagements
