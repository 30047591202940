import React, { useContext, useEffect, useState } from "react";
import generalizePath from "../functions/generalizePath";
import { Route, createRoutesFromElements, matchRoutes, useLocation } from "react-router-dom";

const ApiContext = React.createContext();

export const useApi = () => {
  return useContext(ApiContext);
};

export const ApiProvider = ({ children }) => {
  const location = useLocation();
  const routeEls = (
    <>
      <Route exact path="/" />
      <Route path="/team/pete" />
      <Route path="/deescalation" />
      <Route path="/speaking-engagements" />
      <Route path="/contact" />
      <Route path="/privacy-policy" />
    </>
  );
  const routes = createRoutesFromElements(routeEls);

  const [ip, setIp] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateIp = async () => {
    const res = await fetch("https://api.ipify.org/?format=json");
    if (res.ok) {
      const data = await res.json();
      setIp(data.ip);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateIp();
  }, []);

  const apiFetch = (path, method = "GET", body = null) => {
    return new Promise(async (resolve) => {
      const reqData = { method, headers: {}, body: null };
      var reqIp = ip ? ip : await new Promise(async (resolve) => {
        const res = await fetch("https://api.ipify.org/?format=json");
        if (res.ok) {
          const data = await res.json();
          resolve(data.ip);
        }
        else {
          resolve(null);
        }
      });
      if (reqIp) reqData.headers["X-Forwarded-For"] = reqIp;
      if (body) {
        reqData.headers["Content-Type"] = "application/json";
        reqData.body = JSON.stringify(body);
      }
      const res = await fetch(`/api/${generalizePath(path)}`, reqData);
      resolve(res);
    });
  };

  const sendMetrics = async () => {
    await apiFetch("/metrics", "POST", { path: location.pathname });
  };

  useEffect(() => {
    if (matchRoutes(routes, location.pathname)) sendMetrics();
  }, [location]);

  const value = {
    ip,
    apiFetch
  };

  return (
    <ApiContext.Provider value={value}>
      {(!loading && children) && children}
    </ApiContext.Provider>
  );
};