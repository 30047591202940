import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEye, faRefresh } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import FormatDate from "../../../components/FormatDate";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminMessages = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "messages",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Messages - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/messages",
          meta: {
            charset: "utf-8",
            name: {
              title: "Messages - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,messages,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateMessages();
    };
    unsub();
  }, []);

  const [messages, setMessages] = useState(null);
  const [messagePages, setMessagePages] = useState({ page: 1, pages: 1 });
  const [messagesError, setMessagesError] = useState(null);
  const updateMessages = async (page = 1) => {
    if (page === 1) setMessages(null);
    setMessagesError(null);
    const res = await apiFetch(`/admin/messages?page=${page}${page === 1 ? `&perPage=${messagePages.page * 10}` : ""}`);
    if (res.ok) {
      const data = await res.json();
      setMessagePages({ page: data.page, pages: data.pages });
      if (page === 1) {
        setMessages([...data.messages]);
      }
      else if (messages) {
        setMessages([...messages, ...data.messages]);
      }
      else {
        setMessages(data.messages);
      }
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setMessagesError(data.detail);
      }
      catch {
        setMessagesError("Unable to get messages.");
        setMessages([]);
      }
    }
  };
  const handleNextPage = async () => {
    if (messagePages.page < messagePages.pages) {
      await updateMessages(messagePages.page + 1);
    }
  };
  const handleRefresh = async () => {
    await updateMessages(1);
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!messages) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {messagesError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!messagesError} onHide={() => setMessagesError(null)} variant="danger">
            <span>{messagesError && messagesError}</span>
          </Alert>
        </div>
      )}
      {/* Messages */}
      <div className="card w-full"><div className="card-header flex items-center justify-between">
        <button className="w-fit px-8 btn btn-main flex items-center gap-2" onClick={handleRefresh} disabled={!messages}>
          <span>
            Refresh
          </span>
          <FontAwesomeIcon icon={faRefresh} />
        </button>
      </div>
        <div className="card-body w-full !py-0 !px-2 overflow-scroll hide-scrollbar">
          <table className="w-full admin-table">
            <tbody>
              <tr>
                <th className="text-left">
                  <span>Name</span>
                </th>
                <th className="text-left">
                  <span>Email</span>
                </th>
                <th className="text-left">
                  <span>Phone</span>
                </th>
                <th className="text-left">
                  <span>Timestamp</span>
                </th>
                <th className="text-left">
                  <span>Action</span>
                </th>
              </tr>
              {messages.map(m => {
                return (
                  <tr key={m.messageId}>
                    <td>
                      <span>
                        {m.read === 0 && (
                          <span className="text-yellow-500">
                            <FontAwesomeIcon icon={faCircle} />&nbsp;
                          </span>
                        )}
                        {m.firstName} {m.lastName}
                      </span>
                    </td>
                    <td>
                      <span>
                        {m.email}
                      </span>
                    </td>
                    <td>
                      <span>
                        {m.phone || "N/A"}
                      </span>
                    </td>
                    <td>
                      <span>
                        <FormatDate timestamp={m.timestamp} />
                      </span>
                    </td>
                    <td>
                      <span>
                        <Link to={`/admin/messages/${m.messageId}`} className="link">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {messagePages.page < messagePages.pages && (
              <tfoot>
                <tr>
                  <td colSpan={5} className="text-center">
                    <button className="link" onClick={handleNextPage}>
                      Load more
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminMessages;