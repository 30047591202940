import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminUsers = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "users",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Users - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/users",
          meta: {
            charset: "utf-8",
            name: {
              title: "Users - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,users,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateUsers();
    };
    unsub();
  }, []);

  const [users, setUsers] = useState(null);
  const [userPages, setUserPages] = useState({ page: 1, pages: 1 });
  const [usersError, setUsersError] = useState(null);
  const updateUsers = async (page = 1) => {
    if (page === 1) setUsers(null);
    setUsersError(null);
    const res = await apiFetch(`/admin/users?page=${page}${page === 1 ? `&perPage=${userPages.page * 10}` : ""}`);
    if (res.ok) {
      const data = await res.json();
      setUserPages({ page: data.page, pages: data.pages });
      if (page === 1) {
        setUsers([...data.users]);
      }
      else if (users) {
        setUsers([...users, ...data.users]);
      }
      else {
        setUsers(data.users);
      }
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setUsersError(data.detail);
      }
      catch {
        setUsersError("Unable to get users.");
        setUsers([]);
      }
    }
  };
  const handleNextPage = async () => {
    if (userPages.page < userPages.pages) {
      await updateUsers(userPages.page + 1);
    }
  };
  const handleRefresh = async () => {
    await updateUsers(1);
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!users) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {usersError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!usersError} onHide={() => setUsersError(null)} variant="danger">
            <span>{usersError && usersError}</span>
          </Alert>
        </div>
      )}
      {/* Users */}
      <div className="card w-full">
        <div className="card-header flex items-center justify-between">
          <button className="w-fit px-8 btn btn-main flex items-center gap-2" onClick={handleRefresh} disabled={!users}>
            <span>
              Refresh
            </span>
            <FontAwesomeIcon icon={faRefresh} />
          </button>
          <Link to="/admin/users/add" className="btn btn-main w-fit px-8" role="button">
            Add User <FontAwesomeIcon icon={faPlus} />
          </Link>
        </div>
        <div className="card-body w-full !py-0 !px-2 overflow-scroll hide-scrollbar">
          <table className="w-full admin-table">
            <tbody>
              <tr>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
                <th>
                  <span>Phone</span>
                </th>
                <th>
                  <span>Permission Level</span>
                </th>
                <th>
                  <span>Action</span>
                </th>
              </tr>
              {users.map(u => {
                return (
                  <tr key={u.userId}>
                    <td>
                      <span>
                        {u.firstName} {u.lastName}
                      </span>
                    </td>
                    <td>
                      <span>
                        {u.email}
                      </span>
                    </td>
                    <td>
                      <span>
                        {u.phone || "N/A"}
                      </span>
                    </td>
                    <td>
                      <span>
                        {u.permissionLevel}
                      </span>
                    </td>
                    <td>
                      <span>
                        {currentUser.permissionLevel >= 2 && currentUser.permissionLevel >= u.permissionLevel && (
                          <>
                            <Link to={`/admin/users/${u.userId}/edit`} className="link">
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </Link>
                            &nbsp;
                          </>
                        )}
                        <Link to={`/admin/users/${u.userId}`} className="link">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {userPages.page < userPages.pages && (
              <tfoot>
                <tr>
                  <td colSpan={5} className="text-center">
                    <button className="link" onClick={handleNextPage}>
                      Load more
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminUsers;