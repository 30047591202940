import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRefresh } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "../../../components/PageContainer";
import CustomChart from "../../../components/CustomChart";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import AuthRequired from "../../../components/admin/AuthRequired";
import FormatDate from "../../../components/FormatDate";
import formatDate from "../../../functions/formatDate";
import FormatNumber from "../../../components/FormatNumber";

const AdminMetrics = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();

  const [metrics, setMetrics] = useState(null);
  const [metricsView, setMetricsView] = useState("week");
  const [metricsGraphType, setMetricsGraphType] = useState("area");
  const [metricsDate, setMetricsDate] = useState(formatDate("%Y-%m-%d"));
  const [metricsKey, setMetricsKey] = useState("path");
  const [metricsError, setMetricsError] = useState(null);
  const updateMetrics = async () => {
    setMetrics(null);
    setMetricsError(null);
    const res = await apiFetch(`/admin/metrics/${metricsDate}?view=${metricsView}&key=${metricsKey}`);
    if (res.ok) {
      const data = await res.json();
      setMetrics(data.metrics);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setMetricsError(data.detail);
      }
      catch {
        setMetricsError("Unable to get metrics.");
        setMetrics([{ name: "N/A" }]);
      }
    }
  };
  const handleMetricsView = (e) => {
    setMetricsView(e.target.value);
  };
  const handleMetricsGraphType = (e) => {
    setMetricsGraphType(e.target.value);
  };
  const handleMetricsDate = (e) => {
    setMetricsDate(e.target.value);
  };
  const handleMetricsKey = (e) => {
    setMetricsKey(e.target.value);
  };

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "metrics",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Metrics - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/metrics",
          meta: {
            charset: "utf-8",
            name: {
              title: "Metrics - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,metrics,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateMetrics();
      updateVisits();
    };
    unsub();
  }, [metricsView, metricsDate, metricsKey]);

  const [visits, setVisits] = useState(null);
  const [visitsLoading, setVisitsLoading] = useState(false);
  const [visitPages, setVisitPages] = useState({ page: 1, pages: 1, perPage: null });
  const [visitsError, setVisitsError] = useState(null);
  const updateVisits = async (page = 1) => {
    if (page === 1) setVisits(null);
    setVisitsLoading(true);
    setVisitsError(null);
    const res = await apiFetch(`/admin/metrics/visits?page=${page}${(page === 1 && visitPages.perPage) ? `&perPage=${visitPages.page * visitPages.perPage}` : ""}`);
    if (res.ok) {
      const data = await res.json();
      setVisitPages({ page: data.page, pages: data.pages, perPage: data.perPage });
      if (page === 1) {
        setVisits([...data.visits]);
        setVisitsLoading(false);
      }
      else if (visits) {
        setVisits([...visits, ...data.visits]);
        setVisitsLoading(false);
      }
      else {
        setVisits(data.visits);
        setVisitsLoading(false);
      }
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setVisitsError(data.detail);
        setVisitsLoading(false);
      }
      catch {
        setVisitsError("Unable to get visits.");
        setVisits([]);
        setVisitsLoading(false);
      }
    }
  };
  const handleNextPage = async () => {
    if (visitPages.page < visitPages.pages) {
      await updateVisits(visitPages.page + 1);
    }
  };

  const [summary, setSummary] = useState(null);
  const [summaryError, setSummaryError] = useState(null);
  const updateSummary = async () => {
    setSummaryError(null);
    setSummary(null);
    const res = await apiFetch(`/admin/metrics/summary`);
    if (res.ok) {
      const data = await res.json();
      setSummary(data.summary);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setSummaryError(data.detail);
        setSummary(null);
      }
      catch {
        setSummaryError("Unable to get summary.");
        setSummary(null);
      }
    }
  };

  useEffect(() => {
    const unsub = async () => {
      await updateSummary();
    };
    unsub();
  }, []);

  const handleRefresh = async () => {
    await updateMetrics();
    await updateSummary(1);
    await updateSummary();
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!visits || !metrics || (!summary && !summaryError)) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {(visitsError || metricsError || summaryError) && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!visitsError} onHide={() => setVisitsError(null)} variant="danger">
            <span>{visitsError && visitsError}</span>
          </Alert>
          <Alert hidden={!metricsError} onHide={() => setMetricsError(null)} variant="danger">
            <span>{metricsError && metricsError}</span>
          </Alert>
          <Alert hidden={!summaryError} onHide={() => setSummaryError(null)} variant="danger">
            <span>{summaryError && summaryError}</span>
          </Alert>
        </div>
      )}
      <div className="card w-full">
        <div className="card-header flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <button className="w-fit px-8 btn btn-main flex items-center gap-2" onClick={handleRefresh} disabled={!visits}>
              <span>
                Refresh
              </span>
              <FontAwesomeIcon icon={faRefresh} />
            </button>
            {/* View */}
            <select className="form-control" onChange={handleMetricsView}>
              <option value="day" selected={metricsView === "day"}>Day</option>
              <option value="week" selected={metricsView === "week"}>Week</option>
              <option value="month" selected={metricsView === "month"}>Month</option>
              <option value="year" selected={metricsView === "year"}>Year</option>
            </select>
          </div>
          {/* Controls */}
          <div className="flex flex-col md:grid md:grid-cols-3 gap-2">
            <select className="form-control w-full" onChange={handleMetricsGraphType}>
              <option value="area" selected={metricsGraphType === "area"}>Area Graph</option>
              <option value="bar" selected={metricsGraphType === "bar"}>Bar Graph</option>
              <option value="line" selected={metricsGraphType === "line"}>Line Graph</option>
            </select>
            <div className="flex w-full">
              <input type="date" className="form-control w-full text-left [-webkit-appearance:none]" value={metricsDate} onChange={handleMetricsDate} />
            </div>
            <select className="form-control w-full" onChange={handleMetricsKey}>
              <option value="path" selected={metricsKey === "path"}>Path</option>
              <option value="country" selected={metricsKey === "country"}>Country</option>
              <option value="region" selected={metricsKey === "region"}>Region</option>
              <option value="city" selected={metricsKey === "city"}>City</option>
            </select>
          </div>
          {/* Summary */}
          {summary && (
            <div className="flex flex-col gap-2">
              <hr className="mt-2" />
              <details open>
                <summary className="text-center font-bold text-3xl">Summary</summary>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.day.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 24 hrs
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.day.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 24 hrs
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.day.diffPercent === "number") && summary.day.diffPercent < 0) ? "text-red-600" : ((typeof summary.day.diffPercent === "number") && summary.day.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.day.diffPercent === "number") && summary.day.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.day.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.day.diff < 0) ? "text-red-600" : (summary.day.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.day.diff > 0) ? "+" : ""}<FormatNumber number={summary.day.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.week.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.week.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.week.diffPercent === "number") && summary.week.diffPercent < 0) ? "text-red-600" : ((typeof summary.week.diffPercent === "number") && summary.week.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.week.diffPercent === "number") && summary.week.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.week.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.week.diff < 0) ? "text-red-600" : (summary.week.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.week.diff > 0) ? "+" : ""}<FormatNumber number={summary.week.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.month.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 30 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.month.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 30 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.month.diffPercent === "number") && summary.month.diffPercent < 0) ? "text-red-600" : ((typeof summary.month.diffPercent === "number") && summary.month.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.month.diffPercent === "number") && summary.month.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.month.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.month.diff < 0) ? "text-red-600" : (summary.month.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.month.diff > 0) ? "+" : ""}<FormatNumber number={summary.month.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <h3 className="text-center">Daily Average</h3>
                <div className="grid grid-cols-2">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.week.avg} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.month.avg} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 30 days
                    </span>
                  </div>
                </div>
              </details>
            </div>
          )}
        </div>
        {/* Metrics */}
        {metrics ? (
          <div className="card-body h-[30rem]">
            <CustomChart type={metricsGraphType} data={metrics} className="!max-w-none" colors={{
              GET: "#0073CC",
              POST: "#22C55E",
              PUT: "#EAB308",
              DELETE: "#EF4444",
              Total: "#9CA3AF"
            }} />
          </div>
        ) : (
          <div className="card-body">
            <h3 className="text-center">No data.</h3>
          </div>
        )}
        {/* Visits */}
        <div className="card-body w-full !py-0 !px-2 overflow-scroll hide-scrollbar">
          <table className="w-full admin-table">
            <tbody>
              <tr>
                <th>
                  <span>Path</span>
                </th>
                <th>
                  <span>Location</span>
                </th>
                <th>
                  <span>IP</span>
                </th>
                <th>
                  <span>Timestamp</span>
                </th>
                <th>
                  <span>Action</span>
                </th>
              </tr>
              {visits.map(m => {
                return (
                  <tr key={m.metricsId}>
                    <td>
                      <span className="">
                        <Link to={m.path} className="link" target="_blank">{m.path}</Link>
                      </span>
                    </td>
                    <td>
                      <span>
                        {m.city}, {m.regionCode ? m.regionCode : m.region}, {m.countryCode ? m.countryCode : m.country}
                      </span>
                    </td>
                    <td>
                      <span>
                        <Link to={`/admin/ips/${m.ip}`} className="link">
                          {m.ip}
                        </Link>
                      </span>
                    </td>
                    <td>
                      <FormatDate timestamp={m.timestamp} />
                    </td>
                    <td>
                      <span>
                        <Link to={`/admin/visits/${m.metricsId}`} className="link">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {visitPages.page < visitPages.pages && (
              <tfoot>
                <tr>
                  <td colSpan={5} className="text-center">
                    <button className="link" onClick={handleNextPage} disabled={visitsLoading}>
                      Load more
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminMetrics;