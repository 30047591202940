import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import PageContainer from "../../../components/PageContainer";
import Alert from "../../../components/Alert";
import countries from "../../../extensions/countries";
import AdminNav from "../../../components/admin/AdminNav";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminAddUser = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "addUser",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Add User - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/users",
          meta: {
            charset: "utf-8",
            name: {
              title: "Add User - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,users,add,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      if (currentUser && currentUser.permissionLevel < 2) {
        setError({ detail: "You do not have permission to add users.", all: true, forbidden: true });
      }
    };
    unsub();
  }, []);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const callingCodeRef = useRef();
  const phoneRef = useRef();
  const permissionLevelRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const body = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      username: usernameRef.current.value.toLowerCase(),
      email: emailRef.current.value.toLowerCase(),
      phone: phoneRef.current.value,
      callingCode: callingCodeRef.current.value,
      permissionLevel: parseInt(permissionLevelRef.current.value),
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value
    }
    const res = await apiFetch("/admin/users/add", "POST", body);
    if (res.ok) {
      const data = await res.json();
      setLoading(false);
      navigate(`/admin/users/${data.user.userId}/edit`);
    }
    else {
      try {
        const data = await res.json();
        setError({ ...data, forbidden: (data.statusCode && data.statusCode === 403) });
      }
      catch (e) {
        setError({ detail: "Unable to add user.", stack: e, all: true });
      }
      setLoading(false);
    }
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (error && error.forbidden) return (
    <ErrorPage statusCode={403} statusName="Forbidden" message={error.detail} backLink="/admin/users" backText="Back to users" />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Card */}
      <div className="card w-full max-w-screen-sm mx-auto">
        <div className="card-body flex flex-col gap-4">
          <h2>Add User</h2>
          <form onSubmit={handleSubmit} className="grid md:grid-cols-2 gap-4">
            {/* First Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.firstName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                autoComplete="off"
                className="form-control"
                ref={firstNameRef}
                disabled={loading}
              />
            </div>
            {/* First Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.lastName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="lastName">First Name</label>
              <input
                type="text"
                id="lastName"
                autoComplete="off"
                className="form-control"
                ref={lastNameRef}
                disabled={loading}
              />
            </div>
            {/* Username */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.username) || error.all)) ? "error" : ""}`}>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                autoComplete="off"
                className="form-control"
                ref={usernameRef}
                disabled={loading}
              />
            </div>
            {/* Email Address */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.email) || error.all)) ? "error" : ""}`}>
              <label htmlFor="email">Email Address</label>
              <input
                type="text"
                id="email"
                autoComplete="off"
                className="form-control"
                ref={emailRef}
                disabled={loading}
              />
            </div>
            {/* Phone */}
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.phone) || error.all)) ? "error" : ""}`}>
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group">
                <select ref={callingCodeRef} className="form-control" disabled={loading}>
                  {[...countries.getContinents(true)].map(continent => {
                    return (
                      <optgroup label={continent} key={continent}>
                        {[...countries.getCountriesByContinent(continent, "callingCode")
                          .filter(c => c.callingCode !== null)].map(c => {
                            return (
                              <option value={c.callingCode} key={c.country}>+{c.callingCode} ({c.abbrev ? c.abbrev : c.country})</option>
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </select>
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  ref={phoneRef}
                  autoComplete="off"
                  disabled={loading}
                />
              </div>
            </div>
            {/* Permission Level */}
            <div className={`form-group required ${(error && ((error.errors && error.permissionLevel) || error.all))}`}>
              <label htmlFor="permissionLevel">Permission Level</label>
              <select ref={permissionLevelRef} className="form-control" disabled={loading}>
                <option value="0">0 (Base)</option>
                <option value="2">2 (Manage Users)</option>
              </select>
            </div>
            {/* Password */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.password) || error.all)) ? "error" : ""}`}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                autoComplete="off"
                className="form-control"
                ref={passwordRef}
                disabled={loading}
              />
            </div>
            {/* Confirm Password */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.confirmPassword) || error.all)) ? "error" : ""}`}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                autoComplete="off"
                className="form-control"
                ref={confirmPasswordRef}
                disabled={loading}
              />
            </div>
            {/* Error Message */}
            <Alert variant="danger" className="md:col-span-2" hidden={!error} onHide={() => setError(null)}>
              {error && (
                <>
                  {(typeof error === "string") ? (
                    <span>{error}</span>
                  ) : (
                    <div className="flex flex-col">
                      <span>{error.detail || "Unable to add user."}</span>
                      {error.errors && (
                        <ul className="list-disc">
                          {Object.keys(error.errors)
                            .filter(k => k !== "confirmPassword" || error.errors[k] !== "Passwords do not match.")
                            .map(e => {
                              return (
                                <li key={e} className="ml-5">{error.errors[e]}</li>
                              );
                            })}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}
            </Alert>
            {/* Add User */}
            <button type="submit" className="btn btn-main md:col-span-2" disabled={loading}>
              Add User
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminAddUser;