import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import { getRedirect } from "../../functions/redirect";

const AuthRequired = () => {
  const { currentUser } = useAuth();
  if (!currentUser) return (
    <Navigate to={`/admin/login${getRedirect() ? `?redirect=${getRedirect()}` : ""}`} />
  );
  return null;
};

export default AuthRequired;