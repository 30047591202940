import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import FormatDate from "./FormatDate";

const Footer = () => {
  const handleUnfocus = () => {
    document.activeElement.blur();
  };
  return (
    <footer>
      <div className="footer-container">
        <div className="flex flex-col">
          <p>Copyright &copy; <FormatDate format="%Y" /> <Link to="/" className="footer-link">EVS Solutions Consulting</Link></p>
          <p>Created by <a href="https://isaiahwiesner.com" target="_blank" className="footer-link">Isaiah Wiesner</a></p>
          <p className="hidden md:block"><Link to="/privacy-policy" target="_blank" className="footer-link">Privacy Policy</Link></p>
        </div>
        <div className="footer-btns">
          <a href="https://x.com/evssolutionsca" target="_blank" className="footer-btn" onClick={handleUnfocus}>
            <span className="hidden md:block text-sm">@evssolutionsca</span>
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://instagram.com/evssolutions" target="_blank" className="footer-btn" onClick={handleUnfocus}>
            <span className="hidden md:block text-sm">@evssolutions</span>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="mailto:contact@evssolutions.ca" className="footer-btn" onClick={handleUnfocus}>
            <span className="hidden md:block text-sm">contact@evssolutions.ca</span>
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
          <p><Link to="/privacy-policy" target="_blank" className="footer-link block md:hidden">Privacy Policy</Link></p>
      </div>
    </footer>
  );
};

export default Footer;