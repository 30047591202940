import React, { useEffect, useRef, useState } from "react";

import PageContainer from "../../components/PageContainer";

import { useAuth } from "../../contexts/AuthContext";
import { usePage } from "../../contexts/PageContext";
import Alert from "../../components/Alert";
import { Navigate } from "react-router-dom";
import { getRedirectParam } from "../../functions/redirect";

const AdminLogin = () => {
  const { setCurrentPage } = usePage();
  const { currentUser, logIn } = useAuth();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "adminLogin",
        subpage: null,
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Log In - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/login",
          meta: {
            charset: "utf-8",
            name: {
              title: "Log In - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,login,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  const usernameRef = useRef();
  const passwordRef = useRef();
  const stayLoggedInRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const body = {
      username: usernameRef.current.value,
      password: passwordRef.current.value,
      stayLoggedIn: stayLoggedInRef.current.checked
    };
    const login = await logIn(body);
    if (!login) {
      setLoading(false);
    }
    else {
      setError({ ...login });
      setLoading(false);
    }
  };

  if (currentUser) return (
    <Navigate to={getRedirectParam()} />
  );

  return (
    <PageContainer className="!pt-8">
      <div className="card w-full max-w-screen-sm mx-auto">
        <div className="card-body flex flex-col gap-4">
          <h2>Log In</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
            {/* Username/Email Address */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.username) || error.all)) ? "error" : ""}`}>
              <label htmlFor="username">Username/Email Address</label>
              <input
                type="text"
                id="username"
                autoComplete="off"
                className="form-control"
                ref={usernameRef}
                disabled={loading}
              />
            </div>
            {/* Password */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.password) || error.all)) ? "error" : ""}`}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                autoComplete="off"
                className="form-control"
                ref={passwordRef}
                disabled={loading}
              />
            </div>
            {/* Checkboxes */}
            <div className="form-checkboxes">
              <div className={`form-checkbox ${(error && ((error.errors && error.errors.stayLoggedIn) || error.all)) ? "error" : ""}`}>
                <input
                  type="checkbox"
                  id="stayLoggedIn"
                  className="input-checkbox"
                  ref={stayLoggedInRef}
                  disabled={loading}
                />
                <label htmlFor="stayLoggedIn">Keep me logged in</label>
              </div>
            </div>
            {/* Error Message */}
            <Alert variant="danger" hidden={!error} onHide={() => setError(null)}>
              {error && (
                <>
                  {(typeof error === "string")
                    ? (
                      <span>{error && error}</span>
                    )
                    : Object.keys(error.errors).length < 2
                      ? error.errors && (
                        <ul className="list-disc">
                          {Object.keys(error.errors).map(e => {
                            return (
                              <span key={e}>{error.errors[e]}</span>
                            );
                          })}
                        </ul>
                      )
                      : error.errors.username === error.errors.password
                        ? (
                          <span>{error.errors.username}</span>
                        )
                        : (
                          <div className="flex flex-col">
                            <span>{error.detail || "Unable to log in."}</span>
                            {error.errors && (
                              <ul className="list-disc">
                                {Object.keys(error.errors).map(e => {
                                  return (
                                    <li key={e} className="ml-5">{error.errors[e]}</li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        )}
                </>
              )}
            </Alert>
            {/* Log In */}
            <button type="submit" className="btn btn-main" disabled={loading}>
              Log In
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminLogin;