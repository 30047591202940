import generalizePath from "./generalizePath";

export const getRedirectParam = (base = "/admin") => {
  const redirectParam = window.location.search.match(/(\?|&)redirect=([^\?&]*)/);
  if (redirectParam) return `/${generalizePath(decodeURIComponent(redirectParam[2]))}`;
  return base;
};

export const getRedirect = (base = "/admin") => {
  const path = generalizePath(window.location.pathname);
  if (path === generalizePath(base)) return null;
  return encodeURIComponent(path);
};