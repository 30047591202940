import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faRefresh } from "@fortawesome/free-solid-svg-icons";

import PageContainer from "../../../components/PageContainer";
import CustomChart from "../../../components/CustomChart";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import AuthRequired from "../../../components/admin/AuthRequired";
import FormatDate from "../../../components/FormatDate";
import formatDate from "../../../functions/formatDate";
import FormatNumber from "../../../components/FormatNumber";

const AdminUsage = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();

  const [usage, setUsage] = useState(null);
  const [usageView, setUsageView] = useState("week");
  const [usageGraphType, setUsageGraphType] = useState("area");
  const [usageDate, setUsageDate] = useState(formatDate("%Y-%m-%d"));
  const [usageKey, setUsageKey] = useState("method");
  const [usageError, setUsageError] = useState(null);
  const updateUsage = async () => {
    setUsage(null);
    setRequestsError(null);
    const res = await apiFetch(`/admin/requests/usage/${usageDate}?view=${usageView}&key=${usageKey}`);
    if (res.ok) {
      const data = await res.json();
      setUsage(data.usage);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setUsageError(data.detail);
      }
      catch {
        setUsageError("Unable to get usage.");
        setUsage([{ name: "N/A" }]);
      }
    }
  };
  const handleUsageView = (e) => {
    setUsageView(e.target.value);
  };
  const handleUsageGraphType = (e) => {
    setUsageGraphType(e.target.value);
  };
  const handleUsageDate = (e) => {
    setUsageDate(e.target.value);
  };
  const handleUsageKey = (e) => {
    setUsageKey(e.target.value);
  };

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "usage",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Usage - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca/admin/usage",
          meta: {
            charset: "utf-8",
            name: {
              title: "Usage - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,usage,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateUsage();
      updateRequests();
    };
    unsub();
  }, [usageView, usageDate, usageKey]);

  const [requests, setRequests] = useState(null);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [requestPages, setRequestPages] = useState({ page: 1, pages: 1, perPage: null });
  const [requestsError, setRequestsError] = useState(null);
  const updateRequests = async (page = 1) => {
    if (page === 1) setRequests(null);
    setRequestsLoading(true);
    setRequestsError(null);
    const res = await apiFetch(`/admin/requests?page=${page}${page === 1 && requestPages.perPage ? `&perPage=${requestPages.page * requestPages.perPage}` : ""}`);
    if (res.ok) {
      const data = await res.json();
      setRequestPages({ page: data.page, pages: data.pages });
      if (page === 1) {
        setRequests([...data.requests]);
        setRequestsLoading(false);
      }
      else if (requests) {
        setRequests([...requests, ...data.requests]);
        setRequestsLoading(false);
      }
      else {
        setRequests(data.requests);
        setRequestsLoading(false);
      }
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setRequestsError(data.detail);
        setRequestsLoading(false);
      }
      catch {
        setRequestsError("Unable to get requests.");
        setRequests([]);
        setRequestsLoading(false);
      }
    }
  };
  const handleNextPage = async () => {
    if (requestPages.page < requestPages.pages) {
      await updateRequests(requestPages.page + 1);
    }
  };

  const [summary, setSummary] = useState(null);
  const [summaryError, setSummaryError] = useState(null);
  const updateSummary = async () => {
    setSummaryError(null);
    setSummary(null);
    const res = await apiFetch(`/admin/requests/usage/summary`);
    if (res.ok) {
      const data = await res.json();
      setSummary(data.summary);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setSummaryError(data.detail);
        setSummary(null);
      }
      catch {
        setSummaryError("Unable to get summary.");
        setSummary(null);
      }
    }
  };

  useEffect(() => {
    const unsub = async () => {
      await updateSummary();
    };
    unsub();
  }, []);

  const handleRefresh = async () => {
    await updateUsage();
    await updateRequests(1);
    await updateSummary();
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!requests || !usage || (!summary && !summaryError)) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {(requestsError || usageError || summaryError) && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!requestsError} onHide={() => setRequestsError(null)} variant="danger">
            <span>{requestsError && requestsError}</span>
          </Alert>
          <Alert hidden={!usageError} onHide={() => setUsageError(null)} variant="danger">
            <span>{usageError && usageError}</span>
          </Alert>
          <Alert hidden={!summaryError} onHide={() => setSummaryError(null)} variant="danger">
            <span>{summaryError && summaryError}</span>
          </Alert>
        </div>
      )}
      <div className="card w-full">
        <div className="card-header flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <button className="w-fit px-8 btn btn-main flex items-center gap-2" onClick={handleRefresh} disabled={!requests}>
              <span>
                Refresh
              </span>
              <FontAwesomeIcon icon={faRefresh} />
            </button>
            {/* View */}
            <select className="form-control" onChange={handleUsageView}>
              <option value="day" selected={usageView === "day"}>Day</option>
              <option value="week" selected={usageView === "week"}>Week</option>
              <option value="month" selected={usageView === "month"}>Month</option>
              <option value="year" selected={usageView === "year"}>Year</option>
            </select>
          </div>
          {/* Controls */}
          <div className="flex flex-col md:grid md:grid-cols-3 gap-2">
            <select className="form-control w-full" onChange={handleUsageGraphType}>
              <option value="area" selected={usageGraphType === "area"}>Area Graph</option>
              <option value="bar" selected={usageGraphType === "bar"}>Bar Graph</option>
              <option value="line" selected={usageGraphType === "line"}>Line Graph</option>
            </select>
            <div className="flex w-full">
              <input type="date" className="form-control w-full text-left [-webkit-appearance:none]" value={usageDate} onChange={handleUsageDate} />
            </div>
            <select className="form-control w-full" onChange={handleUsageKey}>
              <option value="method" selected={usageKey === "method"}>Method</option>
              <option value="path" selected={usageKey === "path"}>Path</option>
              <option value="country" selected={usageKey === "country"}>Country</option>
              <option value="region" selected={usageKey === "region"}>Region</option>
              <option value="city" selected={usageKey === "city"}>City</option>
            </select>
          </div>
          {/* Summary */}
          {summary && (
            <div className="flex flex-col gap-2">
              <hr className="mt-2" />
              <details open>
                <summary className="text-center font-bold text-3xl">Summary</summary>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.day.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 24 hrs
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.day.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 24 hrs
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.day.diffPercent === "number") && summary.day.diffPercent < 0) ? "text-red-600" : ((typeof summary.day.diffPercent === "number") && summary.day.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.day.diffPercent === "number") && summary.day.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.day.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.day.diff < 0) ? "text-red-600" : (summary.day.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.day.diff > 0) ? "+" : ""}<FormatNumber number={summary.day.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.week.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.week.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.week.diffPercent === "number") && summary.week.diffPercent < 0) ? "text-red-600" : ((typeof summary.week.diffPercent === "number") && summary.week.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.week.diffPercent === "number") && summary.week.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.week.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.week.diff < 0) ? "text-red-600" : (summary.week.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.week.diff > 0) ? "+" : ""}<FormatNumber number={summary.week.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.month.count} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 30 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1 className="text-center">
                      <FormatNumber number={summary.month.prev} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Previous 30 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex gap-1 items-end">
                      <h1 className={`text-center ${((typeof summary.month.diffPercent === "number") && summary.month.diffPercent < 0) ? "text-red-600" : ((typeof summary.month.diffPercent === "number") && summary.month.diffPercent > 0) ? "text-green-600" : ""}`}>
                        {((typeof summary.month.diffPercent === "number") && summary.month.diffPercent > 0) ? "+" : ""}<FormatNumber number={summary.month.diffPercent * 100} format="short" />%
                      </h1>
                      <span className={`text-sm ${(summary.month.diff < 0) ? "text-red-600" : (summary.month.diff > 0) ? "text-green-600" : ""}`}>
                        {(summary.month.diff > 0) ? "+" : ""}<FormatNumber number={summary.month.diff} format="short" />
                      </span>
                    </div>
                    <span className="text-center text-sm text-gray-400">
                      Difference
                    </span>
                  </div>
                </div>
                <h3 className="text-center">Daily Average</h3>
                <div className="grid grid-cols-2">
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.week.avg} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 7 days
                    </span>
                  </div>
                  <div className="flex flex-col items-center">
                    <h1>
                      <FormatNumber number={summary.month.avg} format="short" />
                    </h1>
                    <span className="text-center text-sm text-gray-400">
                      Last 30 days
                    </span>
                  </div>
                </div>
              </details>
            </div>
          )}
        </div>
        {/* Usage */}
        {usage ? (
          <div className="card-body h-[30rem]">
            <CustomChart type={usageGraphType} data={usage} className="!max-w-none" colors={{
              GET: "#0073CC",
              POST: "#22C55E",
              PUT: "#EAB308",
              DELETE: "#EF4444",
              Total: "#9CA3AF"
            }} />
          </div>
        ) : (
          <div className="card-body">
            <h3 className="text-center">No data.</h3>
          </div>
        )}
        {/* Requests */}
        <div className="card-body w-full !py-0 !px-2 overflow-scroll hide-scrollbar">
          <table className="w-full admin-table">
            <tbody>
              <tr>
                <th>
                  <span>Path</span>
                </th>
                <th>
                  <span>Method</span>
                </th>
                <th>
                  <span>Status</span>
                </th>
                <th>
                  <span>Location</span>
                </th>
                <th>
                  <span>IP</span>
                </th>
                <th>
                  <span>Timestamp</span>
                </th>
                <th>
                  <span>Action</span>
                </th>
              </tr>
              {requests.map(r => {
                return (
                  <tr key={r.requestId}>
                    <td>
                      <span>
                        {r.path}
                      </span>
                    </td>
                    <td>
                      <span className={`!py-0 tag ${r.method === "DELETE"
                        ? "tag-danger"
                        : r.method === "PUT"
                          ? "tag-warning"
                          : r.method === "POST"
                            ? "tag-success"
                            : "tag-main"
                        }`}>
                        {r.method}
                      </span>
                    </td>
                    <td>
                      <span>
                        <span className={`!py-0 tag ${r.ok === 0
                          ? "tag-danger"
                          : "tag-success"
                          }`}>{r.statusCode}</span> {r.statusName}
                      </span>
                    </td>
                    <td>
                      <span>
                        {r.city}, {r.regionCode ? r.regionCode : r.region}, {r.countryCode ? r.countryCode : r.country}
                      </span>
                    </td>
                    <td>
                      <span>
                        <Link to={`/admin/ips/${r.ip}`} className="link">
                          {r.ip}
                        </Link>
                      </span>
                    </td>
                    <td>
                      <FormatDate timestamp={r.timestamp} />
                    </td>
                    <td>
                      <span>
                        <Link to={`/admin/requests/${r.requestId}`} className="link">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {requestPages.page < requestPages.pages && (
              <tfoot>
                <tr>
                  <td colSpan={5} className="text-center">
                    <button className="link" onClick={handleNextPage} disabled={requestsLoading}>
                      Load more
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminUsage;