import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AuthRequired from './components/admin/AuthRequired'

import Homepage from './pages/Homepage'
import Pete from './pages/team/Pete'
import DeEscalation from './pages/DeEscalation'
import Contact from './pages/Contact'

import Admin from './pages/admin/Admin'
import AdminQRGenerator from './pages/admin/AdminQRGenerator'
import AdminLogin from './pages/admin/AdminLogin'
import AdminMessages from './pages/admin/messages/AdminMessages'
import AdminMessage from './pages/admin/messages/AdminMessage'
import AdminUsers from './pages/admin/users/AdminUsers'
import AdminUser from './pages/admin/users/AdminUser'
import AdminAddUser from './pages/admin/users/AdminAddUser'
import AdminEditUser from './pages/admin/users/AdminEditUser'
import AdminIP from './pages/admin/AdminIP'
import AdminUsage from './pages/admin/usage/AdminUsage'
import AdminRequest from './pages/admin/usage/AdminRequest'
import AdminMetrics from './pages/admin/metrics/AdminMetrics'
import AdminVisit from './pages/admin/metrics/AdminVisit'

import PrivacyPolicy from './pages/PrivacyPolicy'
import ErrorPage from './pages/ErrorPage'
import SpeakingEngagements from './pages/SpeakingEngagements'

const App = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={<Homepage />}
        />
        <Route
          path="/team/pete"
          element={<Pete />}
        />
        <Route
          path="/deescalation"
          element={<DeEscalation />}
        />
        <Route
          path="/speaking-engagements"
          element={<SpeakingEngagements />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />

        <Route
          path="/admin"
          element={<Admin />}
        />
        <Route
          path="/admin/login"
          element={<AdminLogin />}
        />
        <Route
          path="/admin/qr-generator"
          element={<AdminQRGenerator />}
        />
        <Route
          path="/admin/messages"
          element={<AdminMessages />}
        />
        <Route
          path="/admin/messages/:messageId"
          element={<AdminMessage />}
        />
        <Route
          path="/admin/users"
          element={<AdminUsers />}
        />
        <Route
          path="/admin/users/add"
          element={<AdminAddUser />}
        />
        <Route
          path="/admin/users/:userId"
          element={<AdminUser />}
        />
        <Route
          path="/admin/users/:userId/edit"
          element={<AdminEditUser />}
        />
        <Route
          path="/admin/ips/:ip"
          element={<AdminIP />}
        />
        <Route
          path="/admin/usage"
          element={<AdminUsage />}
        />
        <Route
          path="/admin/requests/:requestId"
          element={<AdminRequest />}
        />
        <Route
          path="/admin/metrics"
          element={<AdminMetrics />}
        />
        <Route
          path="/admin/visits/:metricsId"
          element={<AdminVisit />}
        />
        <Route
          path="/admin/*"
          element={
            <>
              <AuthRequired />
              <ErrorPage
                backLink="/admin"
                backText="Back to admin dashboard"
                hideNavbar={true}
              />
            </>
          }
        />

        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/*"
          element={<ErrorPage />}
        />
      </Routes>
    </>
  )
}

export default App
