import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PageContainer from "../../components/PageContainer";

import { usePage } from "../../contexts/PageContext";
import { useAuth } from "../../contexts/AuthContext";
import { useApi } from "../../contexts/ApiContext";
import Spinner from "../../components/Spinner";
import Alert from "../../components/Alert";
import AdminNav from "../../components/admin/AdminNav";
import ErrorPage from "../ErrorPage";
import AuthRequired from "../../components/admin/AuthRequired";
import FormatDate from "../../components/FormatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import Map from "../../components/here/Map";

const AdminIP = () => {

  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const { ip: ipParam } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "ip",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "View IP - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/ips/${ipParam}`,
          meta: {
            charset: "utf-8",
            name: {
              title: "View IP - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,ips,view,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateRequest();
    };
    unsub();
  }, []);

  const [ip, setIp] = useState(null);
  const [users, setUsers] = useState(null);
  const [ipError, setIpError] = useState(null);
  const updateRequest = async () => {
    setIp(null);
    const res = await apiFetch(`/admin/ips/${ipParam}`);
    if (res.ok) {
      const data = await res.json();
      setIp(data.ip);
      setUsers(data.users);
    }
    else {
      try {
        const data = await res.json();
        setIpError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setIpError({ detail: "Unable to update IP.", stack: e, all: true });
      }
    }
  };

  const [imageEnlarged, setImageEnlarged] = useState(false);
  const handleEnlargeImage = () => {
    setImageEnlarged(true);
  };
  const handleMinimizeImage = () => {
    setImageEnlarged(false);
  };



  if (!currentUser) return (
    <AuthRequired />
  );

  if (!ip && !ipError) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (ipError && ipError.notFound) return (
    <ErrorPage statusName="IP Not Found" message="The IP you are looking for does not exist. Please check the IP and try again." backLink="/admin" backText="Back to admin dashboard" hideNavbar={true} />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {ipError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!ipError} onHide={() => setIpError(null)} variant="danger">
            <span>{ipError && ipError.detail}</span>
          </Alert>
        </div>
      )}
      {/* IP */}
      {ip && (
        <div className="card w-full">
          <div className="card-body grid md:grid-cols-2 gap-4">
            {/* IP Info */}
            <div className="flex flex-col gap-4">
              <div>
                <h3>
                  {ip.ip}
                </h3>
                <span className="text-gray-400">IP</span>
              </div>
              <div>
                <h3>
                  {ip.org}
                </h3>
                <span className="text-gray-400">Organization</span>
              </div>
              <div>
                <h3>
                  {ip.isp}
                </h3>
                <span className="text-gray-400">Service Provider</span>
              </div>
              <div>
                <h3>
                  {ip.mobile === 1 ? "Yes" : "No"}
                </h3>
                <span className="text-gray-400">Mobile IP</span>
              </div>
            </div>
            {/* Location Info */}
            <div className="flex flex-col gap-2">
              <h4>Location Info</h4>
              {/* {ip.image && (
                <>
                  <img src={ip.image} className="w-full h-auto cursor-zoom-in" onClick={handleEnlargeImage} />
                  {imageEnlarged && (
                    <div className="image-focus" onClick={handleMinimizeImage}>
                      <img src={ip.image} alt={`${ip.lat}, ${ip.lon}`} />
                    </div>
                  )}
                </>
              )} */}
              <Map lat={ip.lat} lng={ip.lon} width="100%" height="15rem" marker={false} radius={12000} />
              <p className="text-sm">
                {ip.city ? `${ip.city},` : ""}{ip.region ? ` ${ip.region}${ip.regionCode ? ` (${ip.regionCode})` : ""}` : ""}
                <br />
                {ip.country ? ` ${ip.country}${ip.countryCode ? ` (${ip.countryCode})` : ""}` : ""}{ip.zip ? ` ${ip.zip}` : ""}
                <br />
                {ip.continent ? ` ${ip.continent}${ip.continentCode ? ` (${ip.continentCode})` : ""}` : ""}
                <br />
                <a
                  href={`https://wego.here.com/discover/${encodeURIComponent(`${ip.lat}, ${ip.lon}`)}/?map=${ip.lat},${ip.lon},12,omv`}
                  target="_blank"
                  className="link"
                >{ip.lat}, {ip.lon} <FontAwesomeIcon icon={faExternalLink} /></a>
              </p>
            </div>
            {/* Users */}
            <div className="flex flex-col gap-2 md:col-span-2">
              <h4>Cached Users</h4>
              {users.length > 0 ? (
                <div className="flex flex-col">
                  {users.map(u => {
                    return (
                      <p key={u.userId} className="text-sm truncate">
                        <Link to={`/admin/users/${u.userId}`} className="link">
                          {u.firstName} {u.lastName} ({u.username})
                        </Link>
                      </p>
                    );
                  })}
                </div>
              ) : (
                <p className="text-sm truncate text-gray-400">
                  No users cached.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AdminIP;