import React from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const CustomChart = ({ className, colors, data, type = "line" }) => {
  var colorPool = (colors && Array.isArray(colors)) ? colors : [
    "#D7263D", "#FF6201", "#FFCB05", "#31D843", "#0073CC", "#642CA9", "#FF36AB",
    "#9D1B2C", "#B84600", "#B89300", "#1D9A2A", "#00457A", "#431D72", "#E00083"
  ];
  const getColor = (k) => {
    if (colors && colors[k]) return colors[k];
    const color = colorPool[0];
    colorPool = [...colorPool.filter(c => c !== color)];
    return color;
  };

  if (type === "bar") return (
    <div className={`${className ? className : ""} w-full max-w-[30rem] h-full`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <YAxis />
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" />

          <Tooltip wrapperStyle={{ opacity: 0.7 }} />
          <Legend />

          {Object.keys(data[0]).filter(k => k !== "name").map(k => {
            const color = getColor(k);
            return (
              <Bar key={k} dataKey={k} fill={color} stroke={color} />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  if (type === "area") return (
    <div className={`${className ? className : ""} w-full max-w-[30rem] h-full`}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data}>
          <YAxis />
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" />

          <Tooltip wrapperStyle={{ opacity: 0.7 }} />
          <Legend />

          {Object.keys(data[0]).filter(k => k !== "name").map(k => {
            const color = getColor(k);
            return (
              <Area key={k} dataKey={k} fill={color} fillOpacity={0.5} stroke={color} />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <div className={`${className ? className : ""} w-full max-w-[30rem] h-full`}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <YAxis />
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" />

          <Tooltip wrapperStyle={{ opacity: 0.7 }} />
          <Legend />

          {Object.keys(data[0]).filter(k => k !== "name").map(k => {
            return (
              <Line key={k} dataKey={k} stroke={getColor(k)} />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomChart;