import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import PageContainer from "../../../components/PageContainer";
import Alert from "../../../components/Alert";
import countries from "../../../extensions/countries";
import Spinner from "../../../components/Spinner";
import AdminNav from "../../../components/admin/AdminNav";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminEditUser = () => {
  const { setCurrentPage } = usePage();
  const { currentUser, updateCurrentUser } = useAuth();
  const { apiFetch } = useApi();
  const { userId } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "editUser",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "Edit User - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/users/${userId}/edit`,
          meta: {
            charset: "utf-8",
            name: {
              title: "Edit User - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,users,edit,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateUser();
    };
    unsub();
  }, []);

  const [user, setUser] = useState(null);
  const updateUser = async () => {
    setUser(null);
    const res = await apiFetch(`/admin/users/${userId}`);
    if (res.ok) {
      const data = await res.json();
      setUser(data.user);
      if (currentUser && currentUser.permissionLevel < 2) {
        setError({ detail: "You do not have permission to edit users.", all: true, forbidden: true });
      }
      else if (currentUser && currentUser.permissionLevel < data.user.permissionLevel) {
        setError({ detail: "You do not have permission to edit this user.", all: true, forbidden: true });
      }
    }
    else {
      try {
        const data = await res.json();
        setError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setError({ detail: "Unable to update user.", stack: e, all: true });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsub = () => {
      if (!user || (error && error.forbidden)) return;
      firstNameRef.current.value = user.firstName;
      lastNameRef.current.value = user.lastName;
      usernameRef.current.value = user.username;
      emailRef.current.value = user.email;
      callingCodeRef.current.selectedIndex = [...callingCodeRef.current.options].filter(op => op.innerText === `+${user.callingCode} (${user.country})`).length > 0 ? [...callingCodeRef.current.options].indexOf([...callingCodeRef.current.options].filter(op => op.innerText === `+${user.callingCode} (${user.country})`)[0]) : 0;
      phoneRef.current.value = user.number;
      permissionLevelRef.current.value = user.permissionLevel;
    };
    unsub();
  }, [user])

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const callingCodeRef = useRef();
  const phoneRef = useRef();
  const permissionLevelRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const body = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      username: usernameRef.current.value.toLowerCase(),
      email: emailRef.current.value.toLowerCase(),
      phone: phoneRef.current.value,
      callingCode: callingCodeRef.current.value,
      permissionLevel: parseInt(permissionLevelRef.current.value),
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value
    }
    const res = await apiFetch(`/admin/users/${userId}/edit`, "PUT", body);
    if (res.ok) {
      setLoading(false);
      await updateUser();
      const data = await res.json();
      if (data.userId === currentUser.userId) {
        updateCurrentUser();
      }
      setMessage(data.detail);
    }
    else {
      try {
        const data = await res.json();
        setError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setError({ detail: "Unable to edit user.", stack: e, all: true });
      }
      setLoading(false);
    }
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!user && !error) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (error && error.forbidden) return (
    <ErrorPage statusCode={403} statusName="Forbidden" message={error.detail} backLink="/admin/users" backText="Back to users" />
  );

  if (error && error.notFound) return (
    <ErrorPage statusName="User Not Found" message="The user you are looking for does not exist. Please check the user ID and try again." backLink="/admin/users" backText="Back to users" hideNavbar={true} />
  );


  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav title={user ? "Edit User" : "User Not Found"} />
      {/* Card */}
      <div className="card w-full max-w-screen-sm mx-auto">
        <div className="card-body flex flex-col gap-4">
          <h2>Edit User</h2>
          <form onSubmit={handleSubmit} className="grid md:grid-cols-2 gap-4">
            {/* First Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.firstName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                autoComplete="off"
                className="form-control"
                ref={firstNameRef}
                disabled={loading}
              />
            </div>
            {/* Last Name */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.lastName) || error.all)) ? "error" : ""}`}>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                autoComplete="off"
                className="form-control"
                ref={lastNameRef}
                disabled={loading}
              />
            </div>
            {/* Username */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.username) || error.all)) ? "error" : ""}`}>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                autoComplete="off"
                className="form-control"
                ref={usernameRef}
                disabled={loading}
              />
            </div>
            {/* Email Address */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.email) || error.all)) ? "error" : ""}`}>
              <label htmlFor="email">Email Address</label>
              <input
                type="text"
                id="email"
                autoComplete="off"
                className="form-control"
                ref={emailRef}
                disabled={loading}
              />
            </div>
            {/* Phone */}
            <div className={`form-group recommended ${(error && ((error.errors && error.errors.phone) || error.all)) ? "error" : ""}`}>
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group">
                <select ref={callingCodeRef} className="form-control" disabled={loading}>
                  {[...countries.getContinents(true)].map(continent => {
                    return (
                      <optgroup label={continent} key={continent}>
                        {[...countries.getCountriesByContinent(continent, "callingCode")
                          .filter(c => c.callingCode !== null)].map(c => {
                            return (
                              <option value={c.callingCode} key={c.country}>+{c.callingCode} ({c.abbrev ? c.abbrev : c.country})</option>
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </select>
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  ref={phoneRef}
                  autoComplete="off"
                  disabled={loading}
                />
              </div>
            </div>
            {/* Permission Level */}
            <div className={`form-group required ${(error && ((error.errors && error.errors.permissionLevel) || error.all)) ? "error" : ""}`}>
              <label htmlFor="permissionLevel">Permission Level</label>
              <select ref={permissionLevelRef} className="form-control" disabled={loading}>
                <option value="0">0 (Base)</option>
                <option value="2">2 (Manage Users)</option>
                {user && user.permissionLevel === 3 && (
                  <option value="3" disabled>3 (Admin)</option>
                )}
              </select>
            </div>
            {/* Password */}
            <div className={`form-group ${(error && ((error.errors && error.errors.password) || error.all)) ? "error" : ""}`}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                autoComplete="off"
                className="form-control"
                ref={passwordRef}
                disabled={loading}
              />
            </div>
            {/* Confirm Password */}
            <div className={`form-group ${(error && ((error.errors && error.errors.confirmPassword) || error.all)) ? "error" : ""}`}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                autoComplete="off"
                className="form-control"
                ref={confirmPasswordRef}
                disabled={loading}
              />
            </div>
            <p className="md:col-span-2 text-center text-sm text-gray-400">
              <FontAwesomeIcon icon={faInfoCircle} /> Leave password fields blank to keep the same password.
            </p>
            {/* Error Message */}
            <Alert variant="danger" className="md:col-span-2" hidden={!error} onHide={() => setError(null)}>
              {error && (
                <>
                  {(typeof error === "string") ? (
                    <span>{error}</span>
                  ) : (
                    <div className="flex flex-col">
                      <span>{error.detail || "Unable to edit user."}</span>
                      {error.errors && (
                        <ul className="list-disc">
                          {Object.keys(error.errors)
                            .filter(k => k !== "confirmPassword" || error.errors[k] !== "Passwords do not match.")
                            .map(e => {
                              return (
                                <li key={e} className="ml-5">{error.errors[e]}</li>
                              );
                            })}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}
            </Alert>
            {/* Message */}
            <Alert variant="success" className="md:col-span-2" hidden={!message} onHide={() => setMessage(null)}>
              {message && (
                <span>{message}</span>
              )}
            </Alert>
            {/* Edit User */}
            <button type="submit" className="btn btn-main md:col-span-2" disabled={loading}>
              Edit User
            </button>
          </form>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminEditUser;