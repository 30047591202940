import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { createRoot } from "react-dom/client";
import "./index.css";

import { PageProvider } from "./contexts/PageContext";
import { ApiProvider } from "./contexts/ApiContext";

import { AuthProvider } from "./contexts/AuthContext";

import App from "./App";

const rootElement = document.getElementById("root");
createRoot(rootElement).render((
  <Router>
    <PageProvider>
      <ApiProvider>
        <AuthProvider>
          <AnimatePresence mode="wait" >
            <App />
          </AnimatePresence>
        </AuthProvider>
      </ApiProvider>
    </PageProvider>
  </Router>
));
