import React, { useEffect } from "react";

import { usePage } from "../../contexts/PageContext";
import PageContainer from "../../components/PageContainer";
import ProgressBar from "../../components/ProgressBar";

const Pete = () => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "team",
        subpage: "pete",
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "Pete Wiesner | EVS Solutions",
          description: "Pete Wiesner - Director of Operations",
          canonical: "https://evssolutions.ca/team/pete",
          meta: {
            charset: "utf-8",
            name: {
              title: "Pete Wiesner | EVS Solutions",
              keywords: "evs,solutions,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "Pete Wiesner - Director of Operations"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  return (
    <PageContainer className="max-w-screen-md flex flex-col items-center gap-8">
      <ProgressBar />
      <div className="flex flex-col gap-2 items-center">
        <h1 className="text-center text-5xl">Pete Wiesner</h1>
        <img src="/imgs/team/pete.png" alt="Pete Wiesner" className="avatar w-48 h-48" />
        <div className="flex flex-col">
          <h2 className="text-center">Director of Operations</h2>
          <span className="text-center text-gray-400">
            <a href="mailto:pete@evssolutions.ca" className="hover:underline">pete@evssolutions.ca</a>
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-justify"><strong>Sergeant Peter Wiesner</strong> has dedicated 23 years of service to the <strong>Hamilton Police Service (HPS)</strong>, where he currently holds the position of <strong>Supervising Sergeant</strong> within the <strong>Crisis Response Branch</strong> (presently on LOA). His expertise lies in the intersection of <strong>mental health</strong>, <strong>addiction</strong>, and <strong>homelessness</strong>, often referred to as the <strong>Marginalized Triangle</strong>.</p>
        <p className="text-justify">Throughout his career, Pete has been deeply committed to supporting vulnerable populations. As part of the crisis response team, he collaborates with community partners to provide assistance to those dealing with homelessness, addiction, and mental health crises. His work involves a <strong>co-response model</strong>, ensuring that individuals receive comprehensive care and support.</p>
        <p className="text-justify">In <strong>2022</strong>, Pete played a pivotal role in securing an <strong>$8.3 million grant</strong> to fund specialized social service workers. This initiative led to the creation of the <strong>Rapid Intervention and Support Team (RIST)</strong>, a community-based program that partners with various sectors to address urgent needs. The RIST complements existing mental health programs, which have been the gold standard for <strong>25 years</strong>.</p>
        <p className="text-justify">Beyond his professional duties, Pete is a <strong>father of two</strong> and an active member of the Hamilton community. He has coached youth sports for over <strong>15 years</strong>, including serving as a coach for the <strong>Hamilton Cardinals baseball team</strong>. His passion for community engagement extends to other local clubs and organizations.</p>
        <p className="text-justify">Pete's openness and courage are evident in his willingness to share personal experiences. He speaks candidly about his own struggles, including <strong>PTSD</strong> resulting from work-related stress and trauma. Additionally, he has bravely addressed his history of <strong>childhood sexual abuse</strong> and the emotional toll of dealing with the deaths of children and colleagues in his line of duty. As a compassionate advocate, Pete has created the Everyone vs. Stigma Foundation which raises funds for grants and scholarships for children of officers who have died from suicide.</p>
      </div>
      <hr />
      <div className="flex flex-col gap-4">
        <h1 className="text-center">Sgt. Pete Wiesner: A Pillar of the Hamilton Community</h1>
        <p className="text-justify">Sgt. Pete Wiesner is a police officer and past provincial correctional officer, has been a beacon of hope and support for the Hamilton community. His dedication to service and his deep understanding of the community's needs led him to establish <strong>EVS Solutions Consulting</strong>.</p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>A Lifetime of Service</h2>
        <p className="text-justify">Pete's career in law enforcement, including his time with the Hamilton Police Service, has given him a unique perspective on the challenges faced by the most marginalized members of our community. His experiences have instilled in him a deep sense of compassion and empathy, which he brings to his work every day.</p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>EVS Solutions Consulting</h2>
        <p className="text-justify">After retiring from active duty, Pete founded EVS Solutions Consulting. This venture allows him to leverage his years of experience and knowledge to navigate complex social issues and drive positive change in the community.</p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Political Involvement</h2>
        <p className="text-justify">In addition to his work with EVS Solutions Consulting, Pete was a PC candidate for the 2023 Hamilton Centre by-election. His political involvement further demonstrates his commitment to serving the community and advocating for those who are often overlooked.</p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Addressing Mental Health, Addiction, and Homelessness</h2>
        <div className="flex flex-col gap-2">
          <p className="text-justify">One of Pete's key initiatives has been to address the critical issues of mental health, addiction, and homelessness in Hamilton. He has successfully leveraged grant opportunities to fund the deployment of social workers on the streets of Hamilton. These dedicated professionals provide much-needed support to individuals dealing with these challenges, helping them access resources and work towards better outcomes. This RIST team has been successfully deployed since 2022.</p>
          <p className="text-justify">Sgt. Pete Wiesner's work continues to have a profound impact on the Hamilton community. His dedication to service, his empathy for those in need, and his tireless efforts to drive positive change are truly inspiring.</p>
        </div>
      </div>
    </PageContainer>
  );
};

export default Pete;