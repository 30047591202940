import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

import bulbLogo from '../assets/Bulb.svg'
import logoPlain from '../assets/Logo-Plain.svg'

const Navbar = ({ active = null, subpage = null }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const handleUnfocus = () => {
    document.activeElement.blur()
  }
  const handleOpenSidebar = () => {
    setSidebarOpen(true)
  }
  const handleCloseSidebar = () => {
    setSidebarOpen(false)
    handleUnfocus()
  }
  const handleToggleDropdown = (e) => {
    var el = e.target || e
    if (el.localName !== 'button') return handleToggleDropdown(el.parentElement)
    handleUnfocus()
    if (el.getAttribute('data-open')) {
      handleCloseDropdown(el)
    } else {
      handleOpenDropdown(el)
    }
  }
  const handleOpenDropdown = (el) => {
    ;[...document.querySelectorAll(['data-open'])].forEach((el) => {
      el.removeAttribute('data-open')
    })
    el.setAttribute('data-open', 1)
  }
  const handleCloseDropdown = (el) => {
    el.removeAttribute('data-open')
  }

  return (
    <nav>
      <div className="nav-container">
        <Link
          to="/"
          className="logo"
          onClick={handleUnfocus}
        >
          <div className="flex gap-4 items-center">
            <img
              src={bulbLogo}
              alt=""
              className="w-auto h-12"
            />
            <img
              src={logoPlain}
              alt="EVS Solutions"
              className="w-auto h-10"
            />
          </div>
        </Link>
        <div className="menubtn-container">
          <button
            className="menubtn"
            onClick={handleOpenSidebar}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div className={`sidebar-bg ${sidebarOpen ? 'open' : ''}`} />
        <div className={`nav-items ${sidebarOpen ? 'open' : ''}`}>
          <div className="mobile-header">
            <button
              className="menubtn"
              onClick={handleCloseSidebar}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <img
              src={logoPlain}
              alt="EVS Solutions"
              className="w-auto h-10"
            />
          </div>
          <Link
            to="/"
            className={`nav-item md:hidden ${
              active === 'home' ? 'active' : ''
            }`}
            onClick={handleCloseSidebar}
          >
            Home
          </Link>
          <button
            className={`nav-dropdown ${active === 'team' ? 'active' : ''}`}
            onClick={handleToggleDropdown}
          >
            <legend>Our Team</legend>
            <div className="nav-dropdown-items">
              <Link
                to="/team/pete"
                className={`nav-dropdown-item ${
                  subpage === 'pete' ? 'active' : ''
                }`}
                onClick={handleCloseSidebar}
              >
                Pete Wiesner
              </Link>
            </div>
          </button>
          {/* <Link to="/consulting" className={`nav-item ${active === "consulting" ? "active" : ""}`} onClick={handleCloseSidebar}>Consulting</Link> */}
          <Link
            to="/deescalation"
            className={`nav-item ${active === 'deescalation' ? 'active' : ''}`}
            onClick={handleCloseSidebar}
          >
            De-Escalation Training
          </Link>
          <Link
            to="/speaking-engagements"
            className={`nav-item ${
              active === 'speaking-engagements' ? 'active' : ''
            }`}
            onClick={handleCloseSidebar}
          >
            Speaking Engagements
          </Link>
          <Link
            to="/contact"
            className={`nav-item ${active === 'contact' ? 'active' : ''}`}
            onClick={handleCloseSidebar}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
