import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";
import FormatDate from "../../../components/FormatDate";

const AdminRequest = () => {

  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const { requestId } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "request",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "View Request - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/requests/${requestId}`,
          meta: {
            charset: "utf-8",
            name: {
              title: "View Request - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,requests,view,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateRequest();
    };
    unsub();
  }, []);

  const [request, setRequest] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const updateRequest = async () => {
    setRequest(null);
    const res = await apiFetch(`/admin/requests/${requestId}`);
    if (res.ok) {
      const data = await res.json();
      setRequest(data.request);
    }
    else {
      try {
        const data = await res.json();
        setRequestError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch (e) {
        setRequestError({ detail: "Unable to update request.", stack: e, all: true });
      }
    }
  };



  if (!currentUser) return (
    <AuthRequired />
  );

  if (!request && !requestError) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (requestError && requestError.notFound) return (
    <ErrorPage statusName="Request Not Found" message="The request you are looking for does not exist. Please check the request ID and try again." backLink="/admin/usage" backText="Back to usage" hideNavbar={true} />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      {/* Alerts */}
      {requestError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!requestError} onHide={() => setRequestError(null)} variant="danger">
            <span>{requestError && requestError.detail}</span>
          </Alert>
        </div>
      )}
      {/* Request */}
      {request && (
        <div className="card w-full">
          <div className="card-body grid md:grid-cols-2 gap-4">
            {/* Request Info */}
            <div className="flex flex-col gap-4">
              <div>
                <h3>
                  {request.path}
                </h3>
                <span className="text-gray-400">Path</span>
              </div>
              <div>
                <h3>
                  <span className={`!py-0 tag ${request.method === "DELETE"
                    ? "tag-danger"
                    : request.method === "PUT"
                      ? "tag-warning"
                      : "tag-success"
                    }`}>{request.method}</span>
                </h3>
                <span className="text-gray-400">Method</span>
              </div>
              <div>
                <h3>
                  <span className={`!py-0 tag ${request.ok === 0
                    ? "tag-danger"
                    : "tag-success"
                    }`}>{request.statusCode}</span> {request.statusName}
                </h3>
                <span className="text-gray-400">Status</span>
              </div>
              <div>
                <h3>
                  <Link to={`/admin/ips/${request.ip}`} className="link">
                    {request.ip}
                  </Link>
                </h3>
                <span className="text-gray-400">IP</span>
              </div>
              <div>
                <h3>
                  <FormatDate timestamp={request.timestamp} />
                </h3>
                <span className="text-gray-400">Timestamp</span>
              </div>
            </div>
            {/* User Info */}
            <div className="flex flex-col gap-2">
              <h4>User Info</h4>
              {request.userId ? (
                <>
                  <div>
                    <p className="text-sm">
                      <Link to={`/admin/users/${request.userId}`} className="link">{request.username}</Link>
                    </p>
                    <p className="text-xs text-gray-400">Username</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      {request.firstName} {request.lastName}
                    </p>
                    <p className="text-xs text-gray-400">Name</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      <a href={`mailto:${request.email}`} className="link">{request.email}</a>
                    </p>
                    <p className="text-xs text-gray-400">Email</p>
                  </div>
                  <div>
                    <p className="text-sm">
                      {request.phone ? (
                        <a href={`tel:${request.phone}`} className="link">{request.phone}</a>
                      ) : "N/A"}
                    </p>
                    <p className="text-xs text-gray-400">Phone</p>
                  </div>
                </>
              ) : (
                <span className="text-gray-400">No user</span>
              )}
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AdminRequest;