import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PageContainer from "../../../components/PageContainer";

import { usePage } from "../../../contexts/PageContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useApi } from "../../../contexts/ApiContext";
import Spinner from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import AdminNav from "../../../components/admin/AdminNav";
import FormatDate from "../../../components/FormatDate";
import ErrorPage from "../../ErrorPage";
import AuthRequired from "../../../components/admin/AuthRequired";

const AdminMessage = () => {
  const { setCurrentPage } = usePage();
  const { currentUser } = useAuth();
  const { apiFetch } = useApi();
  const { messageId } = useParams();

  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "admin",
        subpage: "message",
        hideNavbar: true,
        hideFooter: false,
        meta: {
          title: "View Message - Admin | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: `https://evssolutions.ca/admin/messages/:${messageId}`,
          meta: {
            charset: "utf-8",
            name: {
              title: "View Message - Admin | EVS Solutions",
              keywords: "evs,solutions,admin,messages,view,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
      updateMessage();
    };
    unsub();
  }, []);

  const [message, setMessage] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const updateMessage = async () => {
    setMessage(null);
    setMessageError(null);
    const res = await apiFetch(`/admin/messages/${messageId}`);
    if (res.ok) {
      const data = await res.json();
      setMessage(data.message);
    }
    else {
      try {
        const data = await res.json();
        if (!data.detail) throw new Error("No error");
        setMessageError({ ...data, notFound: (data.statusCode && data.statusCode === 404) });
      }
      catch {
        setMessageError({ detail: "Unable to get message." });
      }
    }
  };


  if (!currentUser) return (
    <AuthRequired />
  );

  if (!message && !messageError) return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav />
      <div className="flex justify-center mt-20">
        <Spinner size={128} />
      </div>
    </PageContainer>
  );

  if (messageError && messageError.notFound) return (
    <ErrorPage statusName="Message Not Found" message="The message you are looking for does not exist. Please check the message ID and try again." backLink="/admin/messages" backText="Back to messages" hideNavbar={true} />
  );

  return (
    <PageContainer className="max-w-screen-md !pt-8 flex flex-col gap-8">
      <AdminNav title={message ? "View Message" : "Message Not Found"} />
      {/* Alerts */}
      {messageError && (
        <div className="flex flex-col gap-2">
          {/* Errors */}
          <Alert hidden={!messageError} onHide={() => setMessageError(null)} variant="danger">
            <span>{messageError && messageError.detail}</span>
          </Alert>
        </div>
      )}
      {/* Message */}
      {message && (
        <div className="card w-full">
          {/* Header */}
          <div className="card-header flex flex-col gap-2">
            <div className="flex items-center gap-4">
              {message.read === 0 && (
                <span className="tag tag-warning">
                  NEW
                </span>
              )}
              <h3>Message from <FormatDate timestamp={message.timestamp} format="%-m/%-d/%Y" /></h3>
            </div>
            <details open>
              <summary className="cursor-pointer">
                <strong>Sender Information</strong>
              </summary>
              <div className="flex flex-col">
                <span>
                  Name: {message.firstName} {message.lastName}
                </span>
                <span>
                  Email: <a href={`mailto:${message.email}`} className="link">{message.email}</a>
                </span>
                <span>
                  Phone: {message.phone ? (
                    <a href={`tel:${message.phone}`} className="link">{message.phone}</a>
                  ) : (
                    <span>N/A</span>
                  )}
                </span>
                <span>
                  Company: {message.company ? message.company : "N/A"}
                </span>
                <span>
                  Position: {message.position ? message.position : "N/A"}
                </span>
              </div>
            </details>
          </div>
          {/* Body */}
          <div className="card-body">
            <pre className="!font-sans">{message.message}</pre>
          </div>
          <div className="card-footer flex flex-col gap-2">
            <div className="flex justify-between">
              <small>Timestamp: <FormatDate timestamp={message.timestamp} /></small>
              <small>
                IP: <Link
                  to={`/admin/ips/${message.ip}`}
                  className="link"
                >{message.ip}</Link>
              </small>
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default AdminMessage;