import React, { useContext, useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PageContext = React.createContext();

export const usePage = () => {
  return useContext(PageContext);
};

export const PageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState({
    id: "home",
    subpage: null,
    hideNavbar: false,
    hideFooter: false,
    meta: {
      title: "EVS Solutions",
      description: "EVS Solutions - Forward Together",
      canonical: "https://evssolutions.ca",
      meta: {
        charset: "utf-8",
        name: {
          title: "EVS Solutions",
          keywords: "evs,solutions,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
          "og:description": "EVS Solutions - Forward Together"
        }
      }
    }
  });

  useEffect(() => {
    const ubsub = () => {
      window.scrollTo(0, 0);
    };
    ubsub();
  }, [currentPage]);

  const value = {
    currentPage,
    setCurrentPage
  };

  return (
    <PageContext.Provider value={value}>
      <DocumentMeta {...currentPage.meta}>
        {!currentPage.hideNavbar && (
          <Navbar active={currentPage.id} subpage={currentPage.subpage} />
        )}
        {children && children}
        {!currentPage.hideFooter && (
          <Footer />
        )}
      </DocumentMeta>
    </PageContext.Provider>
  );
};