import React, { useEffect } from "react";
import { motion } from "framer-motion";

import PageContainer from "../components/PageContainer";
import logoDark from "../assets/Logo-Dark.svg";

import { usePage } from "../contexts/PageContext";
import { Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import Spinner from "../components/Spinner";

const Homepage = () => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "home",
        subpage: null,
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: "Home | EVS Solutions",
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca",
          meta: {
            charset: "utf-8",
            name: {
              title: "Home | EVS Solutions",
              keywords: "evs,solutions,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner",
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  return (
    <PageContainer className="max-w-none px-0 !pt-16 flex flex-col items-center gap-0">
      <ProgressBar />
      <header className="header">
        <img src={logoDark} alt="EVS Solutions" className="w-full max-w-[20rem] h-auto" />
      </header>
      {/* First Section */}
      <section className="max-w-screen-md p-4 mx-auto flex flex-col gap-4">
        {/* Title */}
        <motion.h1
          initial={{ opacity: 0, translateX: "-50px" }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          EVS Solutions: Forward Together
        </motion.h1>
        {/* First Paragraph */}
        <motion.p
          initial={{ opacity: 0, translateX: "-50px" }}
          animate={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-center"
        >At EVS Solutions Consulting, we understand the critical intersection between policing, mental health, and community engagement. With years of expertise and a passion for positive change, we offer innovative solutions tailored to meet the evolving needs of law enforcement agencies, mental health organizations, and communities.</motion.p>
      </section>
      {/* Approach */}
      <motion.section
        initial={{ opacity: 0, translateX: "-50px" }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="w-full bg-main-500 text-white">
        <div className="flex flex-col gap-4 p-4">
          <h2 className="text-center">Our Approach</h2>
          <div className="max-w-screen-lg m-auto flex flex-col gap-4 md:gap-0">
            {/* 1 */}
            <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-0">
              <p className="md:pr-4 text-justify"><strong>1. Policing Transformation:</strong> We work closely with police services to implement progressive strategies aimed at building trust, enhancing accountability, and promoting community-oriented policing. From procedural reforms to training initiatives, we help agencies adapt to modern challenges while upholding the highest standards of professionalism and integrity. When the new CSPA launched on April 1st 2024, critical legislation also changed which will required a navigated and tailored approach to ensuring proper compliance.</p>
              <img src="/imgs/home/policing_transformation.png" alt="Photo of police officers with mental health worker helping a marginalized individual." className="w-full h-auto" />
            </div>
            {/* 2 */}
            <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-0">
              <img src="/imgs/home/mental_health_support.png" alt="Not to spoil the ending, but everything is gonna be okay!" className="w-full h-auto hidden md:block" />
              <p className="md:pl-4 text-justify"><strong>2. Mental Health Support:</strong> Recognizing the complex relationship between law enforcement and mental health, we provide comprehensive training and resources to equip front line workers with the skills needed to effectively interact with individuals in crisis. Our evidence-based programs promote empathy, de-escalation techniques, and collaborative approaches to ensure positive outcomes for both officers and community members.</p>
              <img src="/imgs/home/mental_health_support.png" alt="Not to spoil the ending, but everything is gonna be okay!" className="w-full h-auto md:hidden" />
            </div>
            {/* 3 */}
            <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-2 md:gap-0">
              <p className="md:pr-4 text-justify"><strong>3. Community Partnerships:</strong> We believe that strong partnerships are essential for creating safer, more resilient communities. Through strategic collaboration with local stakeholders, including government agencies, nonprofit organizations, and advocacy groups, we facilitate meaningful dialogue, identify shared goals, and develop sustainable solutions that address the root causes of crime and social inequality</p>
              <img src="/imgs/home/community_partnerships.png" alt="Photo of paramedic SUV parked near St. Patrick's Church serving food to the homeless and marginalized in Hamilton." className="w-full h-auto" />
            </div>
          </div>
        </div>
      </motion.section>
      {/* Why Us */}
      <motion.section
        initial={{ opacity: 0, translateX: "-50px" }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="max-w-screen-md mx-auto p-4 flex flex-col gap-4">
        <h2 className="text-center">Why Choose Us?</h2>
        <div className="flex flex-col gap-2">
          <p className="text-justify"><strong>Expertise:</strong> Our team consists of seasoned professionals with diverse backgrounds in law enforcement, mental health, and community development.</p>
          <p className="text-justify"><strong>Innovation:</strong> We stay ahead of the curve by leveraging the latest research, best practices, and technology to deliver cutting-edge solutions tailored to your unique needs.</p>
          <p className="text-justify"><strong>Impact:</strong> Our proven track record of success speaks for itself. We measure our success not only by metrics but by the tangible improvements we bring to the lives of individuals and communities.</p>
        </div>
      </motion.section>
      {/* Collaborate */}
      <motion.section
        initial={{ opacity: 0, translateX: "-50px" }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="max-w-screen-md mx-auto p-0 px-4 flex flex-col gap-4">
        <h2 className="text-center">Let's Collaborate!</h2>
        <p className="text-center">Together, we can create a safer, more compassionate society where everyone has the opportunity to thrive. Contact us today to learn more about how EVS Solutions can support your organization's mission and vision for the future.</p>
        <Link to="/contact" role="button" className="btn btn-outline-main w-full max-w-[20rem] mx-auto">
          Get In Touch
        </Link>
      </motion.section>
    </PageContainer>
  );
};

export default Homepage;