import React, { useContext, useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import PageContainer from "../components/PageContainer";
import { useApi } from "./ApiContext";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { apiFetch } = useApi();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentLogin, setCurrentLogin] = useState(null);

  useEffect(() => {
    const unsub = async () => {
      await updateCurrentUser();
    };
    unsub();
  }, []);

  const updateCurrentUser = async () => {
    const res = await apiFetch("/auth");
    if (res.ok) {
      const data = await res.json();
      if (data.user) {
        setCurrentLogin(data);
        setCurrentUser(data.user);
        setLoading(false);
      }
      else {
        setCurrentLogin(null);
        setCurrentUser(null);
        setLoading(false);
      }
    }
    else {
      setCurrentLogin(null);
      setCurrentUser(null);
      setLoading(false);
    }
  };

  const logIn = (body) => {
    return new Promise(async (resolve) => {
      const res = await apiFetch("/auth/login", "POST", body);
      if (res.ok) {
        await updateCurrentUser();
        resolve(null);
      }
      else {
        try {
          const data = await res.json();
          resolve({ ...data });
        }
        catch (e) {
          resolve({ detail: "Unable to log in." });
        }
      }
    });
  };

  const logOut = () => {
    return new Promise(async (resolve) => {
      const res = await apiFetch("/auth/logout", "POST");
      if (res.ok) {
        await updateCurrentUser();
        resolve(null);
      }
      else {
        try {
          const data = await res.json();
          resolve({ ...data });
        }
        catch (e) {
          resolve({ detail: "Unable to log out." });
        }
      }
    });
  };

  const value = {
    currentUser,
    currentLogin,
    updateCurrentUser,
    logIn,
    logOut
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <PageContainer className="!pt-32 flex flex-col items-center">
          <Spinner size={128} />
        </PageContainer>
      ) : children && children}
    </AuthContext.Provider>
  );
};