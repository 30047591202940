import React, { useEffect } from "react";

import PageContainer from "../components/PageContainer";
import bulbLogoErr from "../assets/Bulb-Error.svg";

import { usePage } from "../contexts/PageContext";
import { Link } from "react-router-dom";

const ErrorPage = ({ statusCode = 404, statusName = "Page Not Found", message = "The page you are looking for does not exist. Please check the URL and try again.", backLink = "/", backText = "Back to homepage", hideNavbar = false, hideFooter = false }) => {
  const { setCurrentPage } = usePage();
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: "error",
        subpage: null,
        hideNavbar,
        hideFooter,
        meta: {
          title: `${statusCode} - ${statusName} | EVS Solutions`,
          description: "EVS Solutions - Forward Together",
          canonical: "https://evssolutions.ca",
          meta: {
            charset: "utf-8",
            name: {
              title: `${statusCode} - ${statusName} | EVS Solutions`,
              "og:description": "EVS Solutions - Forward Together"
            }
          }
        }
      });
    };
    unsub();
  }, []);

  return (
    <PageContainer className="!pt-24 max-w-screen-md flex flex-col gap-8 items-center">
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 items-center">
          <img src={bulbLogoErr} alt="" className="h-24 w-auto" />
          <h1 className="text-center text-gray-400 text-8xl">{statusCode}</h1>
        </div>
        <h1 className="text-center">{statusName}</h1>
      </div>
      <p className="text-center">{message}</p>
      <p className="text-center">
        <Link to={backLink} className="link">{backText}</Link>
      </p>
    </PageContainer>
  );
};

export default ErrorPage;