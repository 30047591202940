import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Alert = ({ hidden, onHide, variant, children, className, ...props }) => {
  const getStyle = (variant) => {
    const variants = {
      danger: "bg-red-500 bg-opacity-10 text-red-600 border-red-600",
      success: "bg-green-500 bg-opacity-10 text-green-600 border-green-600"
    };
    return variants[variant] || "";
  }
  const getButtonColor = () => {
    const variants = {
      danger: "bg-red-500",
      success: "bg-green-500"
    };
    return variants[variant] || "";
  };
  if (hidden) return null;
  return (
    <div className={`px-4 py-2 border rounded-lg grid grid-cols-[1fr,auto] gap-4 items-center transition-[color,border-color] ${getStyle(variant)} ${className || ""}`} {...props}>
      {children && children}
      {onHide && (
        <button className={`w-8 h-8 rounded-full outline-none ${getButtonColor(variant)} bg-opacity-0 hover:bg-opacity-20 focus:bg-opacity-20 transition-[background-color] duration-150 ease-in-out`} onClick={onHide}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}
    </div>
  );
};

export default Alert;