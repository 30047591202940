import React, { useEffect } from 'react'
import { usePage } from '../contexts/PageContext'
import PageContainer from '../components/PageContainer'
import ProgressBar from '../components/ProgressBar'

const DeEscalation = () => {
  const { setCurrentPage } = usePage()
  useEffect(() => {
    const unsub = () => {
      setCurrentPage({
        id: 'deescalation',
        subpage: null,
        hideNavbar: false,
        hideFooter: false,
        meta: {
          title: 'De-Escalation Training | EVS Solutions',
          description:
            'A comprehensive program designed to equip frontline workers with the necessary skills to effectively interact with individuals in crisis.',
          canonical: 'https://evssolutions.ca/deescalation',
          meta: {
            charset: 'utf-8',
            name: {
              title: 'De-Escalation Training | EVS Solutions',
              keywords:
                'evs,solutions,deescalation,de-escalation,de,escalation,everyone,vs,stigma,consulting,law,enforcement,crisis,intervention,training,cit,international,pete,wiesner',
              'og:description':
                'A comprehensive program designed to equip frontline workers with the necessary skills to effectively interact with individuals in crisis.',
            },
          },
        },
      })
    }
    unsub()
  }, [])
  return (
    <PageContainer className="max-w-screen-md flex flex-col gap-8">
      <ProgressBar />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <h1 className="text-center">
            Specialized Crisis Intervention Training
          </h1>
          <h3 className="text-center">The Memphis Model Approach</h3>
        </div>
        <p className="text-center">
          Our <strong>Specialized Crisis Intervention Training</strong> is a
          comprehensive program designed to equip frontline workers with the
          necessary skills to effectively interact with individuals in crisis.
          This training is based on the renowned <strong>Memphis Model</strong>,
          a proven approach that prioritizes empathy, de-escalation techniques,
          and collaborative problem-solving.
        </p>
      </div>
      <hr />
      <div className="flex flex-col gap-4">
        <h2>Comprehensive Training and Resources</h2>
        <p className="text-justify">
          Our tailored training approach provides a wealth of resources to
          ensure that frontline workers are well-prepared to handle crisis
          situations. We focus on providing a comprehensive understanding of
          crisis intervention, ensuring that our trainees are equipped with the
          knowledge and skills to navigate complex situations effectively.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Evidence-Based Programs</h2>
        <p className="text-justify">
          Our programs are grounded in evidence-based practices that promote{' '}
          <strong>empathy</strong>, <strong>de-escalation techniques</strong>,
          and <strong>collaborative approaches</strong>. These key elements work
          together to ensure positive outcomes for both officers and community
          members. Our goal is to foster an environment of understanding and
          respect, reducing the potential for conflict and ensuring the safety
          of all parties involved.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2>Flexible Training Options</h2>
        <p className="text-justify">
          We understand that every organization has unique needs and
          constraints. That's why we offer flexible training options. You can
          choose to conduct the training in-house, allowing your team to learn
          in a familiar environment. Alternatively, we also offer training at a
          specified location of your choice. Regardless of the training
          location, our commitment to quality and effectiveness remains the
          same.
        </p>
      </div>
      <hr />
      <p className="text-center">
        Join us in our mission to create safer, more understanding environments
        for everyone involved. With our Specialized Crisis Intervention
        Training, we can work together to make a positive impact in our
        communities.
      </p>
    </PageContainer>
  )
}

export default DeEscalation
