import React from "react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePage } from "../../contexts/PageContext";
import { Link } from "react-router-dom";

const AdminNav = ({ title = null }) => {
  const { currentPage } = usePage();
  const pages = {
    dashboard: {
      longTitle: "Admin Dashboard",
      title: "Admin",
      path: "/admin",
      prevPages: null,
    },
    qr: {
      longTitle: "QR Code Generator",
      title: "QR Generator",
      path: "/admin/qr-generator",
      prevPages: ["dashboard"],
    },
    messages: {
      longTitle: null,
      title: "Messages",
      path: "/admin/messages",
      prevPages: ["dashboard"]
    },
    message: {
      longTitle: "View Message",
      title: "Message",
      path: "/admin/messages/:messageId",
      prevPages: ["dashboard", "messages"]
    },
    users: {
      title: "Users",
      path: "/admin/users",
      prevPages: ["dashboard"]
    },
    addUser: {
      longTitle: null,
      title: "Add User",
      path: "/admin/users/add",
      prevPages: ["dashboard", "users"]
    },
    user: {
      longTitle: "Loading...",
      title: "User",
      path: "/admin/users/:userId",
      prevPages: ["dashboard", "users"]
    },
    editUser: {
      longTitle: null,
      title: "Edit User",
      path: "/admin/users/:userId/edit",
      prevPages: ["dashboard", "users"]
    },
    usage: {
      longTitle: "Usage Monitoring",
      title: "Usage",
      path: "/admin/usage",
      prevPages: ["dashboard"]
    },
    metrics: {
      longTitle: null,
      title: "Metrics",
      path: "/admin/metrics",
      prevPages: ["dashboard"]
    },
    request: {
      longTitle: "View Request",
      title: "Request",
      path: "/admin/requests/:requestId",
      prevPages: ["dashboard", "usage"]
    },
    visit: {
      longTitle: "View Page Visit",
      title: "Page Visit",
      path: "/admin/visits/:metricsId",
      prevPages: ["dashboard", "metrics"]
    },
    ip: {
      longTitle: "View IP",
      title: "IP",
      path: "/admin/ips/:ip",
      prevPages: ["dashboard"]
    }
  }
  if (!currentPage || !pages[currentPage.subpage]) return null;
  return (
    <div className="flex flex-wrap items-center">
      {pages[currentPage.subpage].prevPages &&
        <h2 className="text-gray-400 truncate">
          {pages[currentPage.subpage].prevPages.map((pg, i) => {
            return (
              <span key={i}>
                <Link to={pages[pg].path} className="link text-inherit hover:text-inherit focus:text-inherit">
                  {pages[pg].title}
                </Link>
                <span className="text-2xl">
                  &nbsp;<FontAwesomeIcon icon={faAngleRight} />&nbsp;
                </span>
              </span>
            );
          })}
        </h2>
      }
      <h1 className="truncate">
        {title
          ? title
          : pages[currentPage.subpage].longTitle
            ? pages[currentPage.subpage].longTitle
            : pages[currentPage.subpage].title}
      </h1>
    </div>
  );
};

export default AdminNav;