import countryByName from "country-json/src/country-by-name.json";
import countryByAbbrev from "country-json/src/country-by-abbreviation.json";
import countryByCallingCode from "country-json/src/country-by-calling-code.json";
import countryByCapitalCity from "country-json/src/country-by-capital-city.json";
import countryByContinent from "country-json/src/country-by-continent.json";
import countryByCurrencyCode from "country-json/src/country-by-currency-code.json";
import countryByCurrencyName from "country-json/src/country-by-currency-name.json";
import countryByGeoCoords from "country-json/src/country-by-geo-coordinates.json";
import countryByPopulationDensity from "country-json/src/country-by-population-density.json";
import countryByPopulation from "country-json/src/country-by-population.json";
import countryByRegion from "country-json/src/country-by-region-in-world.json";
import countryBySurfaceArea from "country-json/src/country-by-surface-area.json";
import countryByAvgTemp from "country-json/src/country-by-yearly-average-temperature.json";
import countryByCities from "country-json/src/country-by-cities.json";
import countryByFlag from "country-json/src/country-by-flag.json";

const getJsonValue = (data, country, key) => {
  return data.filter(c => c.country === country)[0] ? data.filter(c => c.country === country)[0][key] : null
};

const countryData = countryByName.reduce((con, cur) => {
  con.push({
    country: cur.country,
    abbrev: getJsonValue(countryByAbbrev, cur.country, "abbreviation"),
    callingCode: getJsonValue(countryByCallingCode, cur.country, "calling_code"),
    capitalCity: getJsonValue(countryByCapitalCity, cur.country, "city"),
    continent: getJsonValue(countryByContinent, cur.country, "continent"),
    currencyCode: getJsonValue(countryByCurrencyCode, cur.country, "currency_code"),
    currencyName: getJsonValue(countryByCurrencyName, cur.country, "currency_name"),
    currencyGeoCoords: {
      north: getJsonValue(countryByGeoCoords, cur.country, "north"),
      south: getJsonValue(countryByGeoCoords, cur.country, "south"),
      east: getJsonValue(countryByGeoCoords, cur.country, "east"),
      west: getJsonValue(countryByGeoCoords, cur.country, "west")
    },
    populationDensity: getJsonValue(countryByPopulationDensity, cur.country, "density"),
    population: getJsonValue(countryByPopulation, cur.country, "population"),
    region: getJsonValue(countryByRegion, cur.country, "location"),
    areaKmSq: getJsonValue(countryBySurfaceArea, cur.country, "area"),
    areaMiSq: getJsonValue(countryBySurfaceArea, cur.country, "area") ? Math.floor(getJsonValue(countryBySurfaceArea, cur.country, "area") * 0.386102) : null,
    avgTemp: getJsonValue(countryByAvgTemp, cur.country, "temperature")
  });
  return con;
}, []);

const getCountryCities = (country) => {
  return getJsonValue(countryByCities, country, "cities");
};

const getCountryFlag = (country) => {
  return getJsonValue(countryByFlag, country, "flag_base64");
};

const countries = {
  getContinents: (sort = false, reverse = false) => {
    const data = [...countryData].reduce((con, cur) => {
      if (!con.includes(cur.continent)) con.push(cur.continent);
      return con;
    }, []);
    if (sort) data.sort((a, b) => {
      if (a === "North America") return -1;
      if (b === "North America") return 1;
      if (a === "South America") return -1;
      if (b === "South America") return 1;
      if (a === "Europe") return -1;
      if (b === "Europe") return 1;
      if (a === "Asia") return -1;
      if (b === "Asia") return 1;
      return 0;
    });
    if (reverse) return data.reverse();
    return data;
  },
  getCountries: (sort = null, reverse = false) => {
    const data = [...countryData];
    if (sort) data.sort((a, b) => {
      if (a[sort] !== null && b[sort] === null) return -1;
      else if (a[sort] === null && b[sort] !== null) return 1;
      else if (a[sort] === null && b[sort] === null) return 0;
      else if (typeof a[sort] === "string") {
        return a.localeCompare(b);
      }
      else if (["number", "bigint"].includes(typeof a[sort])) {
        if (a[sort] < b[sort]) return -1;
        if (b[sort] < a[sort]) return 1;
        return 0;
      }
      else if (typeof a[sort] === "boolean") {
        if (a[sort] && !b[sort]) return -1;
        if (!a[sort] && b[sort]) return 1;
        return 0;
      }
      else {
        return 0;
      }
    });
    if (reverse) return data.reverse();
    return data;
  },
  getCountriesByContinent: (continent, sort = null, reverse = false) => {
    return [...countries.getCountries(sort, reverse)].filter(c => c.continent === continent);
  },
  getCountry: (country) => {
    const c = [...countryData].filter(c => c.country === country)[0];
    if (c === null) return c;
    return {
      ...c,
      cities: getCountryCities(country),
      flag: getCountryFlag(country)
    };
  }
};
export default countries;