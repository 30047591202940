export const formatShort = (number) => {
  const suffixes = ["", ..."kmbtPEZY".split("")];
  const powers = [...Array(10)].map((_, i) => i * 3);
  if (isNaN(number) || !isFinite(number)) return "--";
  if (number === 0) return 0;
  return [...[...powers].reverse()].reduce((con, cur) => {
    const th = Math.pow(10, cur);
    if (con === null && Math.abs(number) >= th) con = `${parseFloat((number/th).toFixed(2))}${suffixes[powers.indexOf(cur)]}`;
    return con;
  }, null);
};
export const formatLong = (number) => {
  if (isNaN(number) || !isFinite(number)) return "--";
  return number.toLocaleString();
};
const numberFormatter = { formatShort, formatLong };
export default numberFormatter;