import React from "react";
import { motion } from "framer-motion";

const PageContainer = ({ children, className, ...props }) => {
  return (
    <div
      className={`container ${className ? className : ""}`}
      {...props}
    >
      {children && children}
    </div>
  );
};

export default PageContainer;